

















































import {Component, Prop, Vue} from 'vue-property-decorator';
import DatePickerInput from '@/components/DatePickerInput.vue';
import {UebergabeGutschrift} from '@/store/model';
import {
  RequiredNumberValidationRules,
  RequiredDateValidationRules,
} from '@/formatter';

@Component({
  components: {DatePickerInput},
})
export default class GutschriftenInput extends Vue {
  @Prop({
    type: Array,
    required: true,
    default: [],
  })
  public readonly gutschriften!: UebergabeGutschrift[];
  private readonly amountRules = RequiredNumberValidationRules;
  private readonly dateRules = RequiredDateValidationRules;

  private add(): void {
    this.gutschriften.push({
      amount: '',
      date: '',
      reason: '',
    });
  }

  private remove(i: number): void {
    this.gutschriften.splice(i, 1);
  }
}

import {Payload} from '@/api/payload';

export class Request<T extends Payload> {
  version: string;
  language: string;
  payload: T;

  constructor(payload: T) {
    this.version = '1.0';
    this.language = 'de';
    this.payload = payload;
  }
}

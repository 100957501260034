










































































































































import {FileUploadRequestPayload} from '@/api/request/v1/glaeubiger/fileUploadRequestPayload';
import GlgNumbersSelect from '@/components/glaeubiger/GlgNumbersSelect.vue';
import {Component, Vue} from 'vue-property-decorator';

interface FileInfoWithData {
  name: string;
  description: string;
  type: string;
  fileSizeBytes: number;
  data: string | null;
}

@Component({
  components: {GlgNumbersSelect},
})
export default class Upload extends Vue {
  private readonly allowedMimeTypes = [
    '.xls',
    'application/msexcel', // .xls
    '.xlsx',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
    //'.doc',
    //'application/msword', // .doc
    //'.docx',
    //'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
    //'.odt',
    //'application/vnd.oasis.opendocument.text', // Open office .odt
    //'.ods',
    //'application/vnd.oasis.opendocument.spreadsheet', // Open office .ods
    '.zip',
    'application/zip',
    '.jpeg',
    '.jpg',
    'image/jpeg',
    '.tif',
    '.tiff',
    'image/tiff',
    '.pdf',
    '.csv',
    '.tsv',
    'application/pdf',
  ].join(',');
  private glgNumbers: string[] = [];
  private files: File[] = [];
  private comments = '';
  private loadingUpload = false;
  private showUploadDialog = false;
  private uploadError = false;

  private get fileSizeOverLimit(): boolean {
    const totalBytes = this.files
      .map((f) => f.size)
      .reduce((a, b) => a + b, 0);
    return totalBytes > 10240000;
  }

  private get sendDisabled(): boolean {
    return (
      this.loadingUpload || this.files.length === 0 || this.fileSizeOverLimit
    );
  }

  private getFileSizeKB(file: File): string {
    return String(Math.round((file.size / 1024) * 100) / 100).replace(
      '.',
      ','
    );
  }

  public mounted(): void {
    this.glgNumbers = this.$store.getters['glaeubiger/isAuthenticated']
      ? [this.$store.state.glaeubiger.user.glgNumbers[0]]
      : [];
  }

  private removeFile(i: number): void {
    this.files.splice(i, 1);
  }

  private send(): void {
    if (this.sendDisabled) {
      return;
    }
    this.loadingUpload = true;
    this.showUploadDialog = true;
    this.uploadError = false;
    // Convert each file to base64 one after another for sending them
    const filesBase64: FileInfoWithData[] = [];
    let fileIndex = 0;
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        const data = reader.result
          ? (reader.result as string).split(';base64,')[1]
          : null;
        filesBase64.push({
          name: this.files[fileIndex].name,
          description: this.files[fileIndex].name,
          type: this.files[fileIndex].type,
          fileSizeBytes: this.files[fileIndex].size,
          data: data,
        });
        fileIndex++;
        if (fileIndex < this.files.length) {
          reader.readAsDataURL(this.files[fileIndex]);
        } else {
          this.sendWithBase64Files(filesBase64);
        }
      },
      false
    );
    reader.readAsDataURL(this.files[0]);
  }

  private sendWithBase64Files(filesBase64: FileInfoWithData[]): void {
    const payload = new FileUploadRequestPayload();
    payload.comments = this.comments;
    payload.files = filesBase64;
    payload.glgNumber = this.glgNumbers[0];
    this.$store
      .dispatch('glaeubiger/fileUpload', payload)
      .then(() => {
        this.uploadError = false;
        this.comments = '';
        this.files = [];
      })
      .catch(() => {
        this.uploadError = true;
      })
      .finally(() => {
        this.loadingUpload = false;
      });
  }
}

import {Payload} from '@/api/payload';

export class DashboardResponsePayload extends Payload {
  lastDataUpdateDate: string | null;
  lastAuftragDate: string | null;
  openCasesCount: number | null;
  openCasesHauptfSum: number | null;
  closedCasesCount: number | null;
  closedCasesHauptfSum: number | null;

  constructor(body: {
    lastDataUpdateDate: string | null;
    lastAuftragDate: string | null;
    openCasesCount: number | null;
    openCasesHauptfSum: number | null;
    closedCasesCount: number | null;
    closedCasesHauptfSum: number | null;
  }) {
    super();
    this.lastDataUpdateDate = body.lastDataUpdateDate;
    this.lastAuftragDate = body.lastAuftragDate;
    this.openCasesCount = body.openCasesCount;
    this.openCasesHauptfSum = body.openCasesHauptfSum;
    this.closedCasesCount = body.closedCasesCount;
    this.closedCasesHauptfSum = body.closedCasesHauptfSum;
  }
}

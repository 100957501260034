import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import de from 'vuetify/src/locale/de';
import LanguageGermanIcon from '@/components/icons/LanguageGermanIcon.vue';
import LanguageEnglishIcon from '@/components/icons/LanguageEnglishIcon.vue';
import LogoIcon from '@/components/icons/LogoIcon.vue';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#FF4438',
        secondary: '#FF4438',
        accent: '#FF4438',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
      },
    },
  },
  icons: {
    iconfont: 'mdi',
    values: {
      'ki-logo': {
        component: LogoIcon,
      },
      'lang-de': {
        component: LanguageGermanIcon,
      },
      'lang-en': {
        component: LanguageEnglishIcon,
      },
    },
  },
  lang: {
    locales: {de},
    current: 'de',
  },
});

import { render, staticRenderFns } from "./NachmeldungStellungnahmenDialog.vue?vue&type=template&id=1bb4a1d8&"
import script from "./NachmeldungStellungnahmenDialog.vue?vue&type=script&lang=ts&"
export * from "./NachmeldungStellungnahmenDialog.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
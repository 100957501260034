

















































































































































































































































































import {Component, Vue, Watch} from 'vue-property-decorator';
import {Response} from '@/api/response/response';
import GlgNumbersSelect from '@/components/glaeubiger/GlgNumbersSelect.vue';
import {DashboardResponsePayload} from '@/api/response/v1/glaeubiger/dashboardResponsePayload';
import {DashboardRequestPayload} from '@/api/request/v1/glaeubiger/dashboardRequestPayload';
import {formatDate, formatNumber} from '@/formatter';

@Component({
  components: {GlgNumbersSelect},
})
export default class DashboardGlaeubiger extends Vue {
  private readonly formatNumber = formatNumber;
  private readonly formatDate = formatDate;

  private dialog = false;
  private newsExist = false;
  private news = '';
  private alert = true;

  private glgNumbers: string[] = [];
  private payload: DashboardResponsePayload | null = null;

  public mounted(): void {
    this.updateStatistics();

    
    this.$store
      .dispatch('glaeubiger/news', {
        language: this.$i18n.locale,
      })
      .then((response) => {
        this.news = response['payload']['text'];
        this.newsExist = response['payload']['newsExist'];
      })
      .catch(() => {
        // nothing
      });
  }

  @Watch('glgNumbers')
  private updateStatistics(): void {
    if (this.$store.getters['glaeubiger/isAuthenticated']) {
      this.payload = null;
      const payload = new DashboardRequestPayload();
      payload.glgNumbers = this.glgNumbers;
      this.$store
        .dispatch('glaeubiger/dashboard', payload)
        .then((response: Response<DashboardResponsePayload>) => {
          this.payload = response.payload;
        })
        .catch();
    }
  }

  private logout(): void {
    this.$store
      .dispatch('glaeubiger/logout')
      .then(() => this.$router.push('/glg/login'))
      .catch();
  }
}

import {Payload} from '@/api/payload';

export class LoginResponsePayload extends Payload {
  token: string;
  name: string;
  customerName: string;
  customerNames: string[];
  glgNumbers: string[];
  date: string;
  userAktenTypes: string[];
  aktenTypes: string[];
  aktenTypeNames: string[];
  zinsenEnabled: boolean;
  auskunftEnabled: boolean;

  constructor(body: {
    token: string;
    name: string;
    customerName: string;
    customerNames: string[];
    glgNumbers: string[];
    date: string;
    userAktenTypes: string[];
    aktenTypes: string[];
    aktenTypeNames: string[];
    zinsenEnabled: boolean;
    auskunftEnabled: boolean;
  }) {
    super();
    this.token = body.token;
    this.name = body.name;
    this.customerName = body.customerName;
    this.customerNames = body.customerNames;
    this.glgNumbers = body.glgNumbers;
    this.date = body.date;
    this.userAktenTypes = body.userAktenTypes;
    this.aktenTypes = body.aktenTypes;
    this.aktenTypeNames = body.aktenTypeNames;
    this.zinsenEnabled = body.zinsenEnabled;
    this.auskunftEnabled = body.auskunftEnabled;
  }
}







































































import {NachmeldungStellungnahmenRequestPayload} from '@/api/request/v1/glaeubiger/nachmeldungStellungnahmenRequestPayload';
import {RequiredStringValidationRules} from '@/formatter';
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class NachmeldungStellungnahmenDialog extends Vue {
  @Prop({
    type: Boolean,
    required: true,
  })
  public readonly value!: boolean;
  @Prop({
    type: String,
    required: true,
  })
  public readonly inkassoNumber!: string;
  private text = '';
  private sending = false;
  private sendFinished = false;
  private error = false;
  private valid = true;
  private readonly notEmptyRules = RequiredStringValidationRules;

  private get isOpen(): boolean {
    return this.value;
  }

  private set isOpen(newValue: boolean) {
    this.$emit('input', newValue);
  }

  private close(): void {
    this.text = '';
    this.isOpen = false;
    this.error = false;
    this.sending = false;
    this.sendFinished = false;
  }

  private get isFormValid(): boolean {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const form = this.$refs.form as any;
    return form && form.validate();
  }

  private send(): void {
    if (this.isFormValid) {
      const payload = new NachmeldungStellungnahmenRequestPayload();
      payload.inkassoNumber = this.inkassoNumber;
      payload.text = this.text;
      this.sending = true;
      this.sendFinished = false;
      this.$store
        .dispatch('glaeubiger/uebergabeStellungnahmen', payload)
        .then(() => {
          this.text = '';
          this.error = false;
        })
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          this.sending = false;
          this.sendFinished = true;
        });
    }
  }
}

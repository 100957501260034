import {Payload} from '@/api/payload';

export class PaymentsYearResponsePayload extends Payload {
  years: {
    [year: string]: [
      number, // total HF of the year
      number, // paid HF
      number // not paid
    ];
  } = {};

  constructor(payload: {
    years: {
      [year: string]: [
        number, // total HF of the year
        number, // paid HF
        number // not paid
      ];
    };
  }) {
    super();
    this.years = payload.years;
  }
}

import Vue from 'vue';
import VueRouter, {RouteConfig} from 'vue-router';
import Home from '@/views/Home.vue';
import Login from '@/views/Login.vue';
import Imprint from '@/views/Imprint.vue';
import Privacy from '@/views/Privacy.vue';
// Glaeubiger views
import DashboardGlaeubiger from '@/views/glaeubiger/DashboardGlaeubiger.vue';
import Auftragsuebergabe from '@/views/glaeubiger/Auftragsuebergabe.vue';
import UebergabeHistory from '@/views/glaeubiger/UebergabeHistory.vue';
import ForderungDetails from '@/views/glaeubiger/ForderungDetails.vue';
import Search from '@/views/glaeubiger/Search.vue';
import Glossar from '@/views/glaeubiger/Glossar.vue';
import Upload from '@/views/glaeubiger/Upload.vue';
import Statistics from '@/views/glaeubiger/Statistics.vue';
import Meldungen from '@/views/glaeubiger/Meldungen.vue';
import PasswordChange from '@/views/glaeubiger/PasswordChange.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  // Global routes
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy,
  },
  {
    path: '/imprint',
    name: 'Imprint',
    component: Imprint,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  // Glaeubiger routes
  {
    path: '/glg',
    name: 'DashboardGlaeubiger',
    component: DashboardGlaeubiger,
  },
  {
    path: '/glg/privacy',
    name: 'GlgPrivacy',
    component: Privacy,
  },
  {
    path: '/glg/imprint',
    name: 'GlgImprint',
    component: Imprint,
  },
  {
    path: '/glg/login',
    name: 'LoginGlaeubiger',
    component: Login,
  },
  {
    path: '/glg/glossar',
    name: 'Glossar',
    component: Glossar,
  },
  {
    path: '/glg/auftragsuebergabe',
    name: 'Auftragsuebergabe',
    component: Auftragsuebergabe,
  },
  {
    path: '/glg/uebergabe-history',
    name: 'Uebergabehistorie',
    component: UebergabeHistory,
  },
  {
    path: '/glg/upload',
    name: 'Upload',
    component: Upload,
  },
  {
    path: '/glg/pwchange',
    name: 'Passwort',
    component: PasswordChange,
  },
  {
    path: '/glg/statistics',
    name: 'Statistics',
    component: Statistics,
  },
  {
    path: '/glg/forderungen',
    name: 'Search',
    component: Search,
  },
  {
    path: '/glg/meldungen',
    name: 'Meldungen',
    component: Meldungen,
  },
  {
    path: '/glg/forderungen/:id',
    name: 'Forderung',
    component: ForderungDetails,
  },
  {
    path: '/sch',
    beforeEnter() {
      window.location.href = 'https://serviceportal.koenigs-inkasso.de';
    },
  },
  {
    path: '/sch/*',
    beforeEnter() {
      window.location.href = 'https://serviceportal.koenigs-inkasso.de';
    },
  },
];

const router = new VueRouter({
  routes,
});

export default router;

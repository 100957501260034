import {Payload} from '@/api/payload';

export class LoginRequestPayload extends Payload {
  username = '';
  password = '';

  constructor() {
    super();
  }
}

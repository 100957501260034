import Vue from 'vue';
import Vuex, {Store} from 'vuex';
import {StoreState} from './model';
import glaeubigerModule from './glaeubigerModule';

Vue.use(Vuex);

export default new Store<StoreState>({
  state: {
    i18nEnabled: true,
    glossarEnabled: false,
    contactPersonEnabled: false,
    languageKey: 'de',
  },
  modules: {
    glaeubiger: glaeubigerModule,
  },
  getters: {},
  mutations: {},
  actions: {
    init(): void {
      this.state.languageKey = localStorage.getItem('languageKey') || 'de';
      // TODO: adjust language
    },
    changeLanguage(_, languageKey: string): void {
      // Only store language key if accepted cookies
      if (localStorage.getItem('cookieAccept') === '1') {
        localStorage.setItem('languageKey', languageKey);
      }
      this.state.languageKey = languageKey;
      // TODO: adjust language
    },
  },
});

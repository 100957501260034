import {Payload} from '@/api/payload';
import {Meldung} from '@/store/model';

export class MeldungsHistoryResponsePayload extends Payload {
  currentPage: number;
  totalNumberData: number;
  data: Meldung[];

  constructor(body: {
    currentPage: number;
    totalNumberData: number;
    data: Meldung[];
  }) {
    super();
    this.currentPage = body.currentPage;
    this.totalNumberData = body.totalNumberData;
    this.data = body.data;
  }
}

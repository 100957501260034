var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mb-4"},[_c('v-toolbar',{attrs:{"color":"primary","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.$t('views.glaeubiger.statistics.tableNumber')))]),_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","color":"white"},on:{"click":function($event){_vm.$exporter.exportTableToPrinter(
          _vm.$refs.itemsTable.$el,
          _vm.$t('views.glaeubiger.statistics.tableNumber'),
          _vm.exportInfoLines()
        )}}},[_c('v-icon',[_vm._v("mdi-printer")])],1),_c('v-btn',{attrs:{"small":"","color":"white"},on:{"click":function($event){_vm.$exporter.exportTableToExcel(
          _vm.$refs.itemsTable.$el,
          _vm.$t('views.glaeubiger.statistics.tableNumberTableToExcel'),
          _vm.exportInfoLines()
        )}}},[_c('v-icon',[_vm._v("mdi-microsoft-excel")])],1)],1),(_vm.loading)?_c('div',{staticClass:"text-center"},[_c('v-progress-circular',{staticClass:"mx-auto my-4",attrs:{"indeterminate":"","color":"primary"}})],1):(_vm.error)?_c('v-alert',{staticClass:"ma-4",attrs:{"text":"","type":"error"}},[_vm._v(" "+_vm._s(_vm.$t('views.glaeubiger.statistics.not-loaded'))+" ")]):_c('v-data-table',{ref:"itemsTable",attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.items.length,"sort-by":'year',"sort-desc":true,"hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"header.month12",fn:function(ref){
        var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"header.month11",fn:function(ref){
        var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"header.month10",fn:function(ref){
        var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"header.month9",fn:function(ref){
        var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"header.month8",fn:function(ref){
        var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"header.month7",fn:function(ref){
        var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"header.month6",fn:function(ref){
        var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"header.month5",fn:function(ref){
        var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"header.month4",fn:function(ref){
        var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"header.month3",fn:function(ref){
        var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"header.month2",fn:function(ref){
        var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"header.month1",fn:function(ref){
        var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"header.average",fn:function(ref){
        var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"header.total",fn:function(ref){
        var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"header.year",fn:function(ref){
        var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"item.average",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.average))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import {Payload} from '@/api/payload';

export class FallbetrachtungResponsePayload extends Payload {
  glaeubigergnummer: string;
  ihraz: string;
  hauptforderung: string;
  zinsen: string;
  zahlungen: string;
  aktuellersaldo: string;
  datletztezahl: string;
  schuldner: string;
  negativinfo1: string;
  negativinfo2: string;
  negativinfo3: string;
  negativinfo4: string;
  negativinfo5: string;
  negativinfo6: string;
  aktentyp: string;
  vorletzteaktion: string;
  vorletzteaktionLong: string;
  letzteaktion: string;
  letzteaktionLong: string;
  aktuelleaktion: string;
  aktuelleaktionLong: string;
  schuldnername: string;
  schuldneradresse: string;
  schuldnerplzort: string;

  constructor(body: {
    glaeubigergnummer: string;
    ihraz: string;
    hauptforderung: string;
    zinsen: string;
    zahlungen: string;
    aktuellersaldo: string;
    datletztezahl: string;
    schuldner: string;
    negativinfo1: string;
    negativinfo2: string;
    negativinfo3: string;
    negativinfo4: string;
    negativinfo5: string;
    negativinfo6: string;
    aktentyp: string;
    vorletzteaktion: string;
    vorletzteaktionLong: string;
    letzteaktion: string;
    letzteaktionLong: string;
    aktuelleaktion: string;
    aktuelleaktionLong: string;
    schuldnername: string;
    schuldneradresse: string;
    schuldnerplzort: string;
  }) {
    super();
    this.glaeubigergnummer = body.glaeubigergnummer;
    this.ihraz = body.ihraz;
    this.hauptforderung = body.hauptforderung;
    this.zinsen = body.zinsen;
    this.zahlungen = body.zahlungen;
    this.aktuellersaldo = body.aktuellersaldo;
    this.datletztezahl = body.datletztezahl;
    this.schuldner = body.schuldner;
    this.negativinfo1 = body.negativinfo1;
    this.negativinfo2 = body.negativinfo2;
    this.negativinfo3 = body.negativinfo3;
    this.negativinfo4 = body.negativinfo4;
    this.negativinfo5 = body.negativinfo5;
    this.negativinfo6 = body.negativinfo6;
    this.aktentyp = body.aktentyp;
    this.vorletzteaktion = body.vorletzteaktion;
    this.vorletzteaktionLong = body.vorletzteaktionLong;
    this.letzteaktion = body.letzteaktion;
    this.letzteaktionLong = body.letzteaktionLong;
    this.aktuelleaktion = body.aktuelleaktion;
    this.aktuelleaktionLong = body.aktuelleaktionLong;
    this.schuldnername = body.schuldnername;
    this.schuldneradresse = body.schuldneradresse;
    this.schuldnerplzort = body.schuldnerplzort;
  }
}

import {Payload} from '@/api/payload';
import {UebergabeForderungErhoehung} from '@/store/model';

export class NachmeldungForderungenRequestPayload extends Payload {
  inkassoNumber = '';
  forderungen: UebergabeForderungErhoehung[] = [];

  constructor() {
    super();
  }
}





















































































































































































































































































































































































































































































































































































































import {Component, Vue} from 'vue-property-decorator';
import NachmeldungStellungnahmenDialog from '@/components/glaeubiger/NachmeldungStellungnahmenDialog.vue';
import NachmeldungForderungenDialog from '@/components/glaeubiger/NachmeldungForderungenDialog.vue';
import NachmeldungZahlungenDialog from '@/components/glaeubiger/NachmeldungZahlungenDialog.vue';
import NachmeldungGutschriftenDialog from '@/components/glaeubiger/NachmeldungGutschriftenDialog.vue';
import {FallbetrachtungRequestPayload} from '@/api/request/v1/glaeubiger/fallbetrachtungRequestPayload';
import {Response} from '@/api/response/response';
import {FallbetrachtungResponsePayload} from '@/api/response/v1/glaeubiger/fallbetrachtungResponsePayload';
import {
  FallbetrachtungHistoryResponsePayload,
  FallHistorieEntrySubpayload,
} from '@/api/response/v1/glaeubiger/fallbetrachtungHistoryResponsePayload';
import {
  FallbetrachtungHauptforderungResponsePayload,
  FallHauptforderungEntrySubpayload,
} from '@/api/response/v1/glaeubiger/fallbetrachtungHauptforderungResponsePayload';
import {
  FallbetrachtungZahlungsHistoryResponsePayload,
  FallZahlungsHistoryEntrySubpayload,
} from '@/api/response/v1/glaeubiger/fallbetrachtungZahlungsHistoryResponsePayload';
import {FileDownloadResponsePayload} from '@/api/response/v1/glaeubiger/fileDownloadResponsePayload';

import {formatNumber} from '@/formatter';

@Component({
  components: {
    NachmeldungStellungnahmenDialog,
    NachmeldungForderungenDialog,
    NachmeldungZahlungenDialog,
    NachmeldungGutschriftenDialog,
  },
})
export default class ForderungDetails extends Vue {
  private readonly formatNumber = formatNumber;

  private forderung: FallbetrachtungResponsePayload | null = null;
  private hauptforderung: FallHauptforderungEntrySubpayload[] | null = null;
  private history: FallHistorieEntrySubpayload[] | null = null;
  private zahlungsHistory: FallZahlungsHistoryEntrySubpayload[] | null = null;
  private loading = true;
  private loadingCaseHistory = true;
  private loadingHauptforderung = true;
  private loadingZahlungsHistory = true;
  private stellungnahmenDialogVisible = false;
  private zahlungenDialogVisible = false;
  private gutschriftenDialogVisible = false;
  private forderungenDialogVisible = false;

  private get id(): string {
    return this.$route.params.id;
  }

  private get sortedHistory(): FallHistorieEntrySubpayload[] {
    return this.history ? this.history.sort((a, b) => b.recno - a.recno) : [];
  }

  private get sortedZahlungsHistory(): FallZahlungsHistoryEntrySubpayload[] {
    return this.zahlungsHistory
      ? this.zahlungsHistory.sort((a, b) => b.index - a.index)
      : [];
  }

  private get isGerichtlich(): boolean {
    if (this.forderung === null) {
      return false;
    }
    const aktenType = this.forderung.aktentyp.substring(0, 9).toUpperCase();
    return (
      aktenType === 'GERICHTLI' ||
      aktenType === 'ÜBERWACHU' ||
      aktenType === 'ABGESCHLO'
    );
  }

  private get hasNegativeMerkmale(): boolean {
    return (
      this.forderung !== null &&
      (!this.isEmpty(this.forderung.negativinfo1) ||
        !this.isEmpty(this.forderung.negativinfo2) ||
        !this.isEmpty(this.forderung.negativinfo3) ||
        !this.isEmpty(this.forderung.negativinfo4) ||
        !this.isEmpty(this.forderung.negativinfo5) ||
        !this.isEmpty(this.forderung.negativinfo6))
    );
  }

  private getNegativMerkmalColor(merkmal: string): string {
    return this.isEmpty(merkmal) ? '' : 'red';
  }

  private get ampelColors(): [string, string, string] {
    if (this.hasNegativeMerkmale) {
      return ['#ffffff', '#ffffff', '#ff0000'];
    } else {
      return ['#00ff00', '#ffffff', '#ffffff'];
    }
    //return ['#00ff00', '#ffff00', '#ff0000'];
  }

  private exportInfoLines(): string[][] {
    return [
      [
        this.$t('views.glaeubiger.forderung.table-1.key-1').toString(),
        this.forderung?.ihraz || '',
      ],
      [
        this.$t('views.glaeubiger.forderung.incasso-number').toString(),
        this.id,
      ],
    ];
  }

  private getSchuldnrText(schuldnr: number): string {
    if (schuldnr < 1) {
      return this.$t(
        'views.glaeubiger.forderung.history.schuldner-text-1'
      ).toString();
    }
    if (schuldnr == 1) {
      return this.$t(
        'views.glaeubiger.forderung.history.schuldner-text-2'
      ).toString();
    }
    return (
      this.$t(
        'views.glaeubiger.forderung.history.schuldner-text-3'
      ).toString() +
      ' ' +
      schuldnr
    );
  }

  private getOutputText(type: string): string {
    switch (type.substring(0, 1).toUpperCase()) {
      case 'D':
        return this.$t(
          'views.glaeubiger.forderung.history.ausgabe-1'
        ).toString();
      case 'E':
        return this.$t(
          'views.glaeubiger.forderung.history.ausgabe-2'
        ).toString();
      case 'M':
        return this.$t(
          'views.glaeubiger.forderung.history.ausgabe-3'
        ).toString();
      case 'F':
        return this.$t(
          'views.glaeubiger.forderung.history.ausgabe-4'
        ).toString();
      case 'W':
        return this.$t(
          'views.glaeubiger.forderung.history.ausgabe-5'
        ).toString();
      default:
        return '';
    }
  }

  private isEmpty(s: string): boolean {
    return !s || s.length === 0;
  }

  private mounted(): void {
    this.load();
  }

  private load(): void {
    this.loading = true;
    this.loadingCaseHistory = true;
    this.loadingHauptforderung = true;
    this.loadingZahlungsHistory = true;
    const payload: FallbetrachtungRequestPayload = {
      inkassoNumber: this.id,
    };
    this.$store
      .dispatch('glaeubiger/forderung', payload)
      .then((response: Response<FallbetrachtungResponsePayload>) => {
        this.forderung = response.payload;
        this.loadHistories();
      })
      .catch(() => {
        this.loadingCaseHistory = false;
        this.loadingHauptforderung = false;
        this.loadingZahlungsHistory = false;
      })
      .finally(() => (this.loading = false));
  }

  private generateTdElement(value: string): HTMLTableCellElement {
    const td = document.createElement('td');
    td.innerHTML = value;
    return td;
  }

  private createTableRow(
    keyValue: string,
    value: string
  ): HTMLTableRowElement {
    const tr = document.createElement('tr');
    tr.appendChild(this.generateTdElement(keyValue));
    tr.appendChild(this.generateTdElement(value));
    return tr;
  }

  private createForderungDetailsExportTable(): HTMLElement | null {
    if (this.forderung === null) {
      return null;
    }
    const table = document.createElement('table');
    const firstTr = this.createTableRow(
      this.$t('views.glaeubiger.forderung.table-1.key-1').toString(),
      this.forderung.ihraz
    );
    firstTr.classList.add('header');
    table.appendChild(firstTr);
    table.appendChild(
      this.createTableRow(
        this.$t('views.glaeubiger.forderung.table-1.key-3').toString(),
        this.forderung.aktentyp
      )
    );
    table.appendChild(
      this.createTableRow(
        this.$t('views.glaeubiger.forderung.table-1.key-5').toString(),
        this.forderung.schuldner
      )
    );
    table.appendChild(
      this.createTableRow(
        this.$t('views.glaeubiger.forderung.table-1.key-2').toString(),
        formatNumber(this.forderung.hauptforderung)
      )
    );
    table.appendChild(
      this.createTableRow(
        this.$t('views.glaeubiger.forderung.table-1.key-4').toString(),
        formatNumber(this.forderung.zinsen)
      )
    );
    table.appendChild(
      this.createTableRow(
        this.$t('views.glaeubiger.forderung.table-1.key-6').toString(),
        formatNumber(this.forderung.zahlungen)
      )
    );
    table.appendChild(
      this.createTableRow(
        this.$t('views.glaeubiger.forderung.table-1.key-8').toString(),
        formatNumber(this.forderung.aktuellersaldo)
      )
    );
    table.appendChild(
      this.createTableRow(
        this.$t('views.glaeubiger.forderung.table-1.key-7').toString(),
        this.forderung.datletztezahl || '-'
      )
    );

    table.appendChild(
      this.createTableRow(
        this.$t('views.glaeubiger.forderung.table-1.key-9-1').toString(),
        this.forderung.vorletzteaktionLong
      )
    );
    table.appendChild(
      this.createTableRow(
        this.$t('views.glaeubiger.forderung.table-1.key-9-2').toString(),
        this.forderung.letzteaktionLong
      )
    );
    table.appendChild(
      this.createTableRow(
        this.$t('views.glaeubiger.forderung.table-1.key-9-3').toString(),
        this.forderung.aktuelleaktionLong
      )
    );
    const negativeMerkmal = [];
    if (!this.isEmpty(this.forderung.negativinfo1)) {
      negativeMerkmal.push(
        this.$t('views.glaeubiger.forderung.table-1.chip-1')
      );
    }

    if (!this.isEmpty(this.forderung.negativinfo2)) {
      negativeMerkmal.push(
        this.$t('views.glaeubiger.forderung.table-1.chip-2')
      );
    }

    if (!this.isEmpty(this.forderung.negativinfo3)) {
      negativeMerkmal.push(
        this.$t('views.glaeubiger.forderung.table-1.chip-3')
      );
    }

    if (!this.isEmpty(this.forderung.negativinfo4)) {
      negativeMerkmal.push(
        this.$t('views.glaeubiger.forderung.table-1.chip-4')
      );
    }
    if (!this.isEmpty(this.forderung.negativinfo5)) {
      negativeMerkmal.push(
        this.$t('views.glaeubiger.forderung.table-1.chip-5')
      );
    }

    if (!this.isEmpty(this.forderung.negativinfo6)) {
      negativeMerkmal.push(
        this.$t('views.glaeubiger.forderung.table-1.chip-6')
      );
    }

    table.appendChild(
      this.createTableRow(
        this.$t('views.glaeubiger.forderung.table-1.key-10').toString(),
        negativeMerkmal.length ? negativeMerkmal.join('<br />') : '-'
      )
    );
    return table;
  }

  private loadHistories(): void {
    const payload: FallbetrachtungRequestPayload = {
      inkassoNumber: this.id,
    };
    this.$store
      .dispatch('glaeubiger/forderungHauptforderung', payload)
      .then(
        (response: Response<FallbetrachtungHauptforderungResponsePayload>) =>
          (this.hauptforderung = response.payload.history)
      )
      .catch(() => {
        // nothing
      })
      .finally(() => (this.loadingHauptforderung = false));
    this.$store
      .dispatch('glaeubiger/forderungHistory', payload)
      .then(
        (response: Response<FallbetrachtungHistoryResponsePayload>) =>
          (this.history = response.payload.history)
      )
      .catch(() => {
        // nothing
      })
      .finally(() => (this.loadingCaseHistory = false));
    this.$store
      .dispatch('glaeubiger/forderungZahlungsHistory', payload)
      .then(
        (response: Response<FallbetrachtungZahlungsHistoryResponsePayload>) =>
          (this.zahlungsHistory = response.payload.history)
      )
      .catch(() => {
        // nothing
      })
      .finally(() => (this.loadingZahlungsHistory = false));
  }

  private downloadAttachment(entry: FallHistorieEntrySubpayload): void {
    this.$store
      .dispatch('glaeubiger/downloadFallHistorieAnhang', {
        inkassoNumber: this.id,
        recno: entry.recno,
      })
      .then((response: Response<FileDownloadResponsePayload>) => {
        this.$exporter.downloadFile(
          response.payload.data,
          response.payload.filename,
          response.payload.dataType + ';base64'
        );
      })
      .catch(() => {
        // TODO
      });
  }
}

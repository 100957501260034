import Vue, {PluginObject} from 'vue';

declare module 'vue/types/vue' {
  interface Vue {
    $exporter: Exporter;
  }
}

class Exporter implements PluginObject<Record<string, never>> {
  public install(): void {
    Vue.prototype.$exporter = this;
  }

  public exportTableToExcel(
    element: HTMLElement,
    filename = '',
    infoLines: string[][] = []
  ): void {
    const table = this.getCleanTable(element);

    if (table !== null) {
      const infoLinesText = this.getInfoLinesTable(infoLines);
      const documentText = (infoLinesText + table.outerHTML)
        .replace(/ /g, '%20')
        .replace(/Ä/g, '&Auml;')
        .replace(/ä/g, '&auml;')
        .replace(/Ö/g, '&Öuml;')
        .replace(/ö/g, '&öuml;')
        .replace(/Ü/g, '&Uuml;')
        .replace(/ü/g, '&uuml;')
        .replace(/ß/g, '&szlig;');

      const date = new Date();
      filename = filename
        ? filename +
          '_' +
          date.getDate() +
          '_' +
          (date.getMonth() + 1) +
          '_' +
          date.getFullYear() +
          '.xls'
        : 'excel_data.xls';
      this.downloadFile(documentText, filename, 'application/vnd.ms-excel');
    }
  }

  private getInfoLinesTable(infoLines: string[][]): string {
    if (infoLines.length > 0) {
      return (
        '<table>' +
        infoLines
          .map(
            (l) =>
              '<tr class="header">' +
              l.map((c) => '<td>' + c + '</td>').join('') +
              '</tr>'
          )
          .join('') +
        '</table><br/>'
      );
    }
    return '';
  }

  private getCleanTable(element: HTMLElement): HTMLTableElement | null {
    // If we don't have a table object, search for it
    if (element.tagName.toLowerCase() !== 'table') {
      const tables = element.getElementsByTagName('table');
      // Check if any table is present at all
      if (tables.length === 0) {
        return null;
      }
      element = tables[0];
    }
    const table = element.cloneNode(true) as HTMLTableElement;
    table.style.borderCollapse = 'collapse';
    // replaceWith has side-effects on the DOM, so we have to do
    // the replacement one by one.
    let links = table.getElementsByTagName('a');
    while (links.length > 0) {
      links[0].replaceWith(links[0].innerHTML);
      links = table.getElementsByTagName('a');
    }
    // Remove all buttons
    const buttons = table.getElementsByTagName('button');
    for (let i = buttons.length - 1; i >= 0; i--) {
      buttons[i].remove();
    }
    const tableHeader = table.getElementsByTagName('thead')[0];
    if (tableHeader) {
      const tableHeaderCells = tableHeader.getElementsByTagName('th');
      for (let i = 0; i < tableHeaderCells.length; i++) {
        const cell = tableHeaderCells[i];
        cell.style.borderTop = 'thin solid #000000';
        cell.style.borderBottom = 'thin solid #000000';
        if (i === 0) {
          cell.style.borderLeft = 'thin solid #000000';
        } else if (i === tableHeaderCells.length - 1) {
          cell.style.borderRight = 'thin solid #000000';
        }
        cell.style.textAlign = 'center';
        cell.style.padding = '4px';
      }
    }
    const tableBody = table.getElementsByTagName('tbody')[0];
    if (tableBody) {
      for (const cell of tableBody.getElementsByTagName('td')) {
        cell.style.padding = '4px';
      }
      let lastRow = null;
      for (const row of tableBody.getElementsByTagName('tr')) {
        lastRow = row;
        let first = true;
        for (const cell of row.getElementsByTagName('td')) {
          if (first) {
            cell.style.borderLeft = 'thin solid #000000';
          }
          cell.style.borderRight = 'thin solid #000000';
          first = false;
        }
      }
      if (lastRow !== null) {
        for (const cell of lastRow.getElementsByTagName('td')) {
          cell.style.borderBottom = 'thin solid #000000';
        }
      }
    }
    return table;
  }

  public downloadFile(data: string, filename: string, fileType: string): void {
    // Create a link to the data object and virtually click it
    const downloadLink = document.createElement('a');
    downloadLink.href = 'data:' + fileType + ',' + data;
    downloadLink.download = filename;
    downloadLink.click();
  }

  public exportTableToPrinter(
    element: HTMLElement,
    header: string,
    infoLines: string[][] = []
  ): void {
    const table = this.getCleanTable(element);
    const infoLinesText = this.getInfoLinesTable(infoLines);
    if (table !== null) {
      const win = this.openWindow(
        '',
        '_blank',
        'fullscreen=yes,titlebar=yes,scrollbars=yes'
      );
      if (win === null) {
        return;
      }
      win.document.write(`
        <html>
          <head>
            <title>${window.document.title}</title>
            <meta charset="utf-8">
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
            <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap" rel="stylesheet" />
            <style>
              body {
                font-family: "Roboto", sans-serif;
                font-size: 14px;
              }
              table {
                border-collapse: collapse;
                width: 100%;
                font-size: 14px;
              }
              tr.header {
                border-top: thin solid #000000;
              }
              th {
                border-top: thin solid #000000;
                border-bottom: thin solid #000000;
                text-align: center;
                padding: 4px;
              }
              th:first-child {
                border-left: thin solid #000000;
              }
              th:last-child {
                border-right: thin solid #000000;
              }
              tbody tr td:first-child {
                border-left: thin solid #000000;
                border-right: thin solid #000000;
              }
              tbody tr td:not(:first-child) {
                border-right: thin solid #000000;
              }
              tbody tr:last-child td {
                border-bottom: thin solid #000000;
              }
              td {
                padding: 4px;
              }
              td.text-start {
                text-align: start;
              }
              td.text-left {
                text-align: left;
              }
              td.text-end {
                text-align: end;
              }
              td.text-right {
                text-align: right;
              }
              h2 {
                text-align:center;
              }
            </style>
          </head>
          <body>
            <h2> ${header} </h2>
            ${infoLinesText}
            ${table.outerHTML}
            <br/>
            <div style="text-align:center;">K&ouml;nigs Inkasso<br/>http://www.koenigs-inkasso.de</div>
          </body>
        </html>
      `);
      //addStyles(win, styles);
      setTimeout(() => {
        win.document.close();
        win.focus();
        win.print();
        setTimeout(function () {
          window.close();
        }, 1);
      }, 1000);
    }
  }

  private openWindow(url: string, name: string, props: string): Window | null {
    let windowRef = null;
    /*eslint-disable no-constant-condition */
    if (/*@cc_on!@*/ false) {
      // for IE only
      windowRef = window.open('', name, props);
      windowRef.close();
    }
    windowRef = window.open(url, name, props);
    if (windowRef !== null) {
      if (!windowRef.opener) {
        windowRef.opener = self;
      }
      windowRef.focus();
    }
    return windowRef;
  }
}

export const exporter = new Exporter();
export default exporter;















































































































import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {Response} from '@/api/response/response';
import {TakeoversQuantityResponsePayload} from '@/api/response/v1/glaeubiger/statistics/takeoversQuantityResponsePayload';
import {formatNumber} from '@/formatter';

@Component
export default class StatisticTakeoversYearMonthQuantityCard extends Vue {
  @Prop({
    type: Array,
    required: true,
  })
  public readonly glgNumbers!: string[];

  private readonly formatNumber = formatNumber;
  private loading = false;
  private error = false;
  private readonly headers = [
    {text: 'views.glaeubiger.statistics.tableYear', value: 'year'},
    {
      text: 'views.glaeubiger.statistics.tableTotal',
      value: 'total',
      align: 'end',
    },
    {
      text: 'views.glaeubiger.statistics.tableAverage',
      value: 'average',
      align: 'end',
    },
    {
      text: 'views.glaeubiger.statistics.tableMonth1',
      value: 'month1',
      align: 'end',
    },
    {
      text: 'views.glaeubiger.statistics.tableMonth2',
      value: 'month2',
      align: 'end',
    },
    {
      text: 'views.glaeubiger.statistics.tableMonth3',
      value: 'month3',
      align: 'end',
    },
    {
      text: 'views.glaeubiger.statistics.tableMonth4',
      value: 'month4',
      align: 'end',
    },
    {
      text: 'views.glaeubiger.statistics.tableMonth5',
      value: 'month5',
      align: 'end',
    },
    {
      text: 'views.glaeubiger.statistics.tableMonth6',
      value: 'month6',
      align: 'end',
    },
    {
      text: 'views.glaeubiger.statistics.tableMonth7',
      value: 'month7',
      align: 'end',
    },
    {
      text: 'views.glaeubiger.statistics.tableMonth8',
      value: 'month8',
      align: 'end',
    },
    {
      text: 'views.glaeubiger.statistics.tableMonth9',
      value: 'month9',
      align: 'end',
    },
    {
      text: 'views.glaeubiger.statistics.tableMonth10',
      value: 'month10',
      align: 'end',
    },
    {
      text: 'views.glaeubiger.statistics.tableMonth11',
      value: 'month11',
      align: 'end',
    },
    {
      text: 'views.glaeubiger.statistics.tableMonth12',
      value: 'month12',
      align: 'end',
    },
  ];
  private items: {
    year: number;
    total: number;
    average: number;
    month1: number;
    month2: number;
    month3: number;
    month4: number;
    month5: number;
    month6: number;
    month7: number;
    month8: number;
    month9: number;
    month10: number;
    month11: number;
    month12: number;
  }[] = [];

  public mounted(): void {
    this.loading = true;
    this.error = false;
  }

  @Watch('glgNumbers')
  private updateStatistics(): void {
    this.loading = true;
    this.error = false;
    this.$store
      .dispatch('glaeubiger/statisticsTakeoversYearMonthQuantity', {
        glgNumbers: this.glgNumbers,
      })
      .then((response: Response<TakeoversQuantityResponsePayload>) => {
        this.items = [];
        const years = Object.keys(response.payload.years);
        for (let i = 0; i < years.length; i++) {
          const year = parseInt(years[i]);
          const total = response.payload.years[year].reduce(
            (x: number, y: number) => x + y,
            0
          );
          this.items.push({
            year: year,
            total: total,
            average: total / 12.0,
            month1: response.payload.years[year][0],
            month2: response.payload.years[year][1],
            month3: response.payload.years[year][2],
            month4: response.payload.years[year][3],
            month5: response.payload.years[year][4],
            month6: response.payload.years[year][5],
            month7: response.payload.years[year][6],
            month8: response.payload.years[year][7],
            month9: response.payload.years[year][8],
            month10: response.payload.years[year][9],
            month11: response.payload.years[year][10],
            month12: response.payload.years[year][11],
          });
        }
      })
      .catch(() => (this.error = false))
      .finally(() => (this.loading = false));
  }

  private exportInfoLines(): string[][] {
    return [
      [
        this.$t('views.glaeubiger.statistics.glaubigerNumber').toString(),
        this.glgNumbers.join(', '),
      ],
      [
        this.$t('views.glaeubiger.statistics.glaubigerName').toString(),
        this.$store.state.glaeubiger.user.customerName,
      ],
    ];
  }
}

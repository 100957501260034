











import {Component, Vue} from 'vue-property-decorator';
import PrivacyContentGlaeubiger from '@/components/glaeubiger/PrivacyContentGlaeubiger.vue';

@Component({
  components: {PrivacyContentGlaeubiger},
})
export default class Privacy extends Vue {

}

import {Payload} from '@/api/payload';

export class FallbetrachtungHistoryResponsePayload extends Payload {
  history: FallHistorieEntrySubpayload[];

  constructor(body: {history: FallHistorieEntrySubpayload[]}) {
    super();
    this.history = body.history;
  }
}

export class FallHistorieEntrySubpayload {
  datum: string;
  text: string;
  schuldnr: number;
  status: string;
  sachbear: string;
  recno: number;
  kategtx: string;
  ausgabe: string;
  hasAttachment: boolean;

  constructor() {
    this.datum = '';
    this.text = '';
    this.schuldnr = 0;
    this.status = '';
    this.sachbear = '';
    this.recno = 0;
    this.kategtx = '';
    this.ausgabe = '';
    this.hasAttachment = false;
  }
}





































































































































































































































































































































































































































































































































































































































































































import {AuftragUebergabeRequestPayload} from '@/api/request/v1/glaeubiger/auftragUebergabeRequestPayload';
import {Component, Vue, Watch} from 'vue-property-decorator';
import GlgNumbersSelect from '@/components/glaeubiger/GlgNumbersSelect.vue';
import GutschriftenInput from '@/components/glaeubiger/GutschriftenInput.vue';
import ZahlungenUebergabeInput from '@/components/glaeubiger/ZahlungenUebergabeInput.vue';
import ForderungenUebergabeInput from '@/components/glaeubiger/ForderungenUebergabeInput.vue';
import DatePickerInput from '@/components/DatePickerInput.vue';
import CountryCodeInput from '@/components/CountryCodeInput.vue';
import {AuftragsuebergabeData} from '@/store/model';
import {
  OptionalIBANValidationRules,
  RequiredStringValidationRules,
  DateValidationRules,
} from '@/formatter';

interface FileInfoWithData {
  name: string;
  description: string;
  type: string;
  fileSizeBytes: number;
  data: string | null;
}

@Component({
  components: {
    GutschriftenInput,
    ZahlungenUebergabeInput,
    ForderungenUebergabeInput,
    DatePickerInput,
    CountryCodeInput,
    GlgNumbersSelect,
  },
})
export default class Auftragsuebergabe extends Vue {
  private currentStep = 1;
  private errorMessage = '';
  private files: File[] =[]
  private readonly allowedMimeTypes = [
    '.xls',
    'application/msexcel', // .xls
    '.xlsx',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
    //'.doc',
    //'application/msword', // .doc
    //'.docx',
    //'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
    //'.odt',
    //'application/vnd.oasis.opendocument.text', // Open office .odt
    //'.ods',
    //'application/vnd.oasis.opendocument.spreadsheet', // Open office .ods
    '.zip',
    'application/zip',
    '.jpeg',
    '.jpg',
    'image/jpeg',
    '.tif',
    '.tiff',
    'image/tiff',
    '.pdf',
    '.csv',
    '.tsv',
    'application/pdf',
  ].join(',');
  private uebergabe: AuftragsuebergabeData = {
    glgNumber: '',
    geschaeftsId: '',
    inkassozession: 'false',
    eigentumsvorbehalt: 'true',
    salutation: 1,
    legalForm: 'privat männlich',
    legalFormOther: '',
    firstName: '',
    lastName: '',
    jobSector: '',
    birthDate: '',
    addressStreet: '',
    addressNumber: '',
    addressPostalCode: '',
    addressCity: '',
    addressCountryCode: 'D',
    telephone: '',
    mobile: '',
    fax: '',
    email: '',
    blz: '',
    konto: '',
    bic: '',
    iban: '',
    legalGuardianSalutation: 1,
    legalGuardianFirstName: '',
    legalGuardianLastName: '',
    files:  [], // File[] =
    // We require at least one forderung
    forderungen: [
      {
        invoiceSum: '',
        invoiceDate: '',
        invoiceNumber: '',
        contractDate: '',
        firstReminderDate: '',
        bankCosts: '',
        reminderFees: '',
      },
    ],
    zahlungen: [],
    gutschriften: [],
    remarks: '',
  };
  private comments = '';
  private readonly salutationItems = [
    {value: 1, text: 'views.glaeubiger.mandate-transfer.salutation-item-1'},
    {value: 2, text: 'views.glaeubiger.mandate-transfer.salutation-item-2'},
    {value: 3, text: 'views.glaeubiger.mandate-transfer.salutation-item-3'},
  ];
  private readonly legalFormItems = [
    {
      value: 'privat männlich',
      text: 'views.glaeubiger.mandate-transfer.legal-form-items-1',
    },
    {
      value: 'privat weiblich',
      text: 'views.glaeubiger.mandate-transfer.legal-form-items-2',
    },
    {
      value: 'Einzelfirma',
      text: 'views.glaeubiger.mandate-transfer.legal-form-items-3',
    },
    {
      value: 'GmbH',
      text: 'views.glaeubiger.mandate-transfer.legal-form-items-4',
    },
    {
      value: 'GmbH & Co. KG',
      text: 'views.glaeubiger.mandate-transfer.legal-form-items-5',
    },
    {
      value: 'Gewerbebetrieb',
      text: 'views.glaeubiger.mandate-transfer.legal-form-items-6',
    },
    {
      value: 'e.V.',
      text: 'views.glaeubiger.mandate-transfer.legal-form-items-7',
    },
    {
      value: 'AG',
      text: 'views.glaeubiger.mandate-transfer.legal-form-items-8',
    },
    {
      value: 'KG',
      text: 'views.glaeubiger.mandate-transfer.legal-form-items-9',
    },
    {
      value: 'Andere',
      text: 'views.glaeubiger.mandate-transfer.legal-form-items-10',
    },
  ];
  private form1Valid = true;
  private form2Valid = true;
  private form3Valid = true;
  private form4Valid = true;
  private form5Valid = true;
  private form6Valid = true;
  // private form7Valid = true;
  private readonly notEmptyRules = RequiredStringValidationRules;
  private readonly ibanRules = OptionalIBANValidationRules;
  private readonly dateRules = DateValidationRules;
  private showSendingDialog = false;
  private sending = false;
  private sendError = false;
  private showValidationErrorDialog = false;
  private readonly currentStepItems = [
    {text: 'views.glaeubiger.mandate-transfer.step-1-title', value: 1},
    {text: 'views.glaeubiger.mandate-transfer.step-2-title', value: 2},
    {text: 'views.glaeubiger.mandate-transfer.step-3-title', value: 3},
    {text: 'views.glaeubiger.mandate-transfer.step-4-title', value: 4},
    {text: 'views.glaeubiger.mandate-transfer.step-5-title', value: 5},
    {text: 'views.glaeubiger.mandate-transfer.step-6-title', value: 6},
    // {text: 'views.glaeubiger.mandate-transfer.step-7-title', value: 7},
  ];

  private get glgNumber(): string[] {
    return [this.uebergabe.glgNumber];
  }

  private set glgNumber(newValue: string[]) {
    this.uebergabe.glgNumber = newValue[0];
  }

  private get firstNameLabel(): string {
    return this.uebergabe.salutation === 3
      ? this.$t('views.glaeubiger.mandate-transfer.firstName2').toString()
      : this.$t('views.glaeubiger.mandate-transfer.firstName').toString();
  }

  private get lastNameLabel(): string {
    return this.uebergabe.salutation === 3
      ? this.$t('views.glaeubiger.mandate-transfer.lastName2').toString()
      : this.$t('views.glaeubiger.mandate-transfer.lastName').toString();
  }

  private get lastNameRules() {
    return this.uebergabe.salutation === 3 ? [] : this.notEmptyRules;
  }

  private get legalGuardianFirstNameLabel(): string {
    return this.uebergabe.legalGuardianSalutation === 3
      ? this.$t(
          'views.glaeubiger.mandate-transfer.legalGuardianFirstName2'
        ).toString()
      : this.$t(
          'views.glaeubiger.mandate-transfer.legalGuardianFirstName1'
        ).toString();
  }

  private get legalGuardianLastNameLabel(): string {
    return this.uebergabe.legalGuardianSalutation === 3
      ? this.$t(
          'views.glaeubiger.mandate-transfer.legalGuardianLastName2'
        ).toString()
      : this.$t(
          'views.glaeubiger.mandate-transfer.legalGuardianLastName1'
        ).toString();
  }

  @Watch('uebergabe.salutation')
  private onSalutationChanged(newValue: string, oldValue: string): void {
    if (oldValue === newValue) {
      return;
    }
    if (this.uebergabe.salutation === 1) {
      this.uebergabe.legalForm = 'privat männlich';
    } else if (this.uebergabe.salutation === 2) {
      this.uebergabe.legalForm = 'privat weiblich';
    } else {
      this.uebergabe.legalForm = 'GmbH';
    }
  }

  @Watch('uebergabe.legalForm')
  private onLegalFormChanged(newValue: string, oldValue: string): void {
    if (oldValue === newValue) {
      return;
    }
    if (this.uebergabe.legalForm === 'privat männlich') {
      this.uebergabe.salutation = 1;
    } else if (this.uebergabe.legalForm === 'privat weiblich') {
      this.uebergabe.salutation = 2;
    }
    if (this.uebergabe.legalForm !== 'Andere') {
      this.uebergabe.legalFormOther = '';
    }
  }

  public mounted(): void {
    this.currentStep = 1;
    this.errorMessage = '';
    this.uebergabe = {
      glgNumber: this.$store.state.glaeubiger.user.glgNumbers[0],
      geschaeftsId: '',
      inkassozession: 'false',
      eigentumsvorbehalt: 'true',
      salutation: 1,
      legalForm: 'privat männlich',
      legalFormOther: '',
      firstName: '',
      lastName: '',
      jobSector: '',
      birthDate: '',
      addressStreet: '',
      addressNumber: '',
      addressPostalCode: '',
      addressCity: '',
      addressCountryCode: 'D',
      telephone: '',
      mobile: '',
      fax: '',
      email: '',
      blz: '',
      konto: '',
      bic: '',
      iban: '',
      files: [],
      legalGuardianSalutation: 1,
      legalGuardianFirstName: '',
      legalGuardianLastName: '',
      // We require at least one forderung
      forderungen: [
        {
          invoiceSum: '',
          invoiceDate: '',
          invoiceNumber: '',
          contractDate: '',
          firstReminderDate: '',
          bankCosts: '',
          reminderFees: '',
        },
      ],
      zahlungen: [],
      gutschriften: [],
      remarks: '',
    };
    this.$nextTick(() => {
      for (let i = 1; i <= 6; i++) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const form = this.$refs['form' + i] as any;
        if (form) {
          form.validate();
        }
      }
    });
  }

  private moveBackwards(): void {
    if (this.currentStep > 1) {
      this.currentStep--;
    }
  }

  private moveForward(): void {
    if (this.currentStep <6 && this.isFormValid(this.currentStep)) {
      this.currentStep++;
    }
  }

  private isFormValid(index: number): boolean {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const form = this.$refs['form' + index] as any;
    return form && form.validate();
  }

  private get allFormsValid(): boolean {
    let valid = true;
    for (let i = 1; i <= 6; i++) {
      if (!this.isFormValid(i)) {
        valid = false;
      }
    }
    return valid;
  }

  
  private getFileSizeKB(file: File): string {
    return String(Math.round((file.size / 1024) * 100) / 100).replace(
      '.',
      ','
    );
  }

  private removeFile(i: number): void {
    this.files.splice(i, 1);
  }

  private get fileSizeOverLimit(): boolean {
    const totalBytes = this.files
      .map((f) => f.size)
      .reduce((a, b) => a + b, 0);
    return totalBytes > 10240000;
  }

  
  private get sendDisabled(): boolean {
    return (
      this.fileSizeOverLimit
    );
  }

  private send(): void {
    if (this.sendDisabled) {
      return;
    }
    if (this.allFormsValid) {
      this.showSendingDialog = true;
      this.sendError = false;
      this.errorMessage = '';
      this.sending = true;
      const filesBase64: FileInfoWithData[] = [];
      let fileIndex = 0;
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        () => {
          const data = reader.result
            ? (reader.result as string).split(';base64,')[1]
            : null;
          filesBase64.push({
            name: this.files[fileIndex].name,
            description: this.files[fileIndex].name,
            type: this.files[fileIndex].type,
            fileSizeBytes: this.files[fileIndex].size,
            data: data,
          });
          fileIndex++;
          if (fileIndex < this.files.length) {
            reader.readAsDataURL(this.files[fileIndex]);
          } 
          else {
            this.sendWithBase64Files(filesBase64);
          }
        },
        false
      );
      if(this.files.length>0){
        reader.readAsDataURL(this.files[0]);
      }
      else{
        this.sendWithBase64Files(filesBase64);
      }
    }else {
      this.showValidationErrorDialog = true;
    }
  }

  private sendWithBase64Files(filesBase64: FileInfoWithData[]): void {
    this.uebergabe.files= filesBase64;
    const payload: AuftragUebergabeRequestPayload = {...this.uebergabe};
    this.$store
        .dispatch('glaeubiger/auftraguebergabe', payload)
        .then(() => {
          this.sendError = false;
          this.uebergabe = {
            glgNumber: this.$store.state.glaeubiger.user.glgNumbers[0],
            geschaeftsId: '',
            inkassozession: 'false',
            eigentumsvorbehalt: 'true',
            salutation: 1,
            legalForm: 'privat männlich',
            legalFormOther: '',
            firstName: '',
            lastName: '',
            jobSector: '',
            birthDate: '',
            addressStreet: '',
            addressNumber: '',
            addressPostalCode: '',
            addressCity: '',
            addressCountryCode: 'D',
            telephone: '',
            mobile: '',
            fax: '',
            email: '',
            blz: '',
            konto: '',
            bic: '',
            iban: '',
            files: [],
            legalGuardianSalutation: 1,
            legalGuardianFirstName: '',
            legalGuardianLastName: '',
            // We require at least one forderung
            forderungen: [
              {
                invoiceSum: '',
                invoiceDate: '',
                invoiceNumber: '',
                contractDate: '',
                firstReminderDate: '',
                bankCosts: '',
                reminderFees: '',
              },
            ],
            zahlungen: [],
            gutschriften: [],
            remarks: '',
          };
          this.files=[];
          this.currentStep=1;
          })
        .catch((error) => {
          this.sendError = true;
          this.errorMessage = error.response.data.error;
        })
        .finally(() => (this.sending = false));
  }
}

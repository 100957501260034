import {Payload} from '@/api/payload';
import {UebergabeZahlungErhoehung} from '@/store/model';

export class NachmeldungZahlungenRequestPayload extends Payload {
  inkassoNumber = '';
  zahlungen: UebergabeZahlungErhoehung[] = [];

  constructor() {
    super();
  }
}

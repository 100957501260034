var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mb-4"},[_c('v-toolbar',{attrs:{"color":"primary","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.$t('views.glaeubiger.statistics.tablePayments')))]),_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","color":"white"},on:{"click":function($event){_vm.$exporter.exportTableToPrinter(
          _vm.$refs.itemsTable.$el,
          _vm.$t('views.glaeubiger.statistics.tablePayments'),
          _vm.exportInfoLines()
        )}}},[_c('v-icon',[_vm._v("mdi-printer")])],1),_c('v-btn',{attrs:{"small":"","color":"white"},on:{"click":function($event){_vm.$exporter.exportTableToExcel(
          _vm.$refs.itemsTable.$el,
          _vm.$t('views.glaeubiger.statistics.tablePaymentsTableToExcel'),
          _vm.exportInfoLines()
        )}}},[_c('v-icon',[_vm._v("mdi-microsoft-excel")])],1)],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"3","md":"3"}},[_c('date-picker-input',{attrs:{"label":_vm.$t('views.glaeubiger.search.dateMin')},model:{value:(_vm.dateMin),callback:function ($$v) {_vm.dateMin=$$v},expression:"dateMin"}})],1),_c('v-col',{attrs:{"cols":"6","sm":"3","md":"3"}},[_c('date-picker-input',{attrs:{"label":_vm.$t('views.glaeubiger.search.dateMax')},model:{value:(_vm.dateMax),callback:function ($$v) {_vm.dateMax=$$v},expression:"dateMax"}})],1),_c('v-col',[_c('v-btn',{attrs:{"color":"primary","disabled":_vm.loading},on:{"click":_vm.search}},[_vm._v(" "+_vm._s(_vm.$t('views.glaeubiger.search.button'))+" ")])],1)],1)],1),(_vm.loading)?_c('div',{staticClass:"text-center"},[_c('v-progress-circular',{staticClass:"mx-auto my-4",attrs:{"indeterminate":"","color":"primary"}})],1):(_vm.error)?_c('v-alert',{staticClass:"ma-4",attrs:{"text":"","type":"error"}},[_vm._v(" "+_vm._s(_vm.$t('views.glaeubiger.statistics.not-loaded'))+" ")]):_c('v-data-table',{ref:"itemsTable",attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.items.length,"sort-by":'yearMonth',"sort-desc":true,"must-sort":"","hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
        var item = ref.item;
return [_c('tr',{class:item.month !== null ? '' : 'font-weight-bold grey lighten-2'},[_c('td',[_vm._v(" "+_vm._s((item.month !== null ? item.month.toString().padStart(2, '0') + '.' : '') + item.year)+" ")]),_c('td',{staticClass:"text-end"},[_vm._v(" "+_vm._s(_vm.formatNumber(item.total))+" ")]),_c('td',{staticClass:"text-end"},[_vm._v(" "+_vm._s(_vm.formatNumber(item.paid))+" ")]),_c('td',{staticClass:"text-end"},[_vm._v(" "+_vm._s(_vm.formatNumber(item.left))+" ")]),_c('td',{staticClass:"text-end"},[_vm._v(_vm._s(_vm.formatNumber(item.netto))+"%")])])]}},{key:"body.append",fn:function(){return [_c('tr',{staticClass:"font-weight-bold grey lighten-2"},[_c('td',[_vm._v(" "+_vm._s(_vm.$t('views.glaeubiger.statistics.statistic-payments-year.total'))+" ")]),_c('td',{staticClass:"text-end"},[_vm._v(" "+_vm._s(_vm.formatNumber(_vm.total.total))+" ")]),_c('td',{staticClass:"text-end"},[_vm._v(" "+_vm._s(_vm.formatNumber(_vm.total.paid))+" ")]),_c('td',{staticClass:"text-end"},[_vm._v(" "+_vm._s(_vm.formatNumber(_vm.total.left))+" ")]),_c('td',{staticClass:"text-end"},[_vm._v(_vm._s(_vm.formatNumber(_vm.total.netto))+"%")])])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import {Payload} from '@/api/payload';

export class FileUploadRequestPayload extends Payload {
  glgNumber = '';
  comments = '';
  files: {
    name: string;
    description: string;
    type: string;
    fileSizeBytes: number;
    data: string | null;
  }[] = [];

  constructor() {
    super();
  }
}

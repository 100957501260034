var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column flex-grow-0",staticStyle:{"max-height":"100% !important","height":"100% !important","min-height":"100% !important"}},[_c('v-card',{staticClass:"mb-4"},[_c('v-toolbar',{attrs:{"color":"primary","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.$t('views.glaeubiger.uebergabe-history.title')))])],1),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('glg-numbers-select',{model:{value:(_vm.glgNumbers),callback:function ($$v) {_vm.glgNumbers=$$v},expression:"glgNumbers"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"label":_vm.$t('views.glaeubiger.uebergabe-history.user-id'),"outlined":"","hide-details":"","dense":""},model:{value:(_vm.userId),callback:function ($$v) {_vm.userId=$$v},expression:"userId"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('v-select',{attrs:{"items":_vm.locationItems,"attach":"","label":_vm.$t('views.glaeubiger.uebergabe-history.country'),"outlined":"","hide-details":"","dense":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(item.text))+" ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(item.text))+" ")]}}]),model:{value:(_vm.location),callback:function ($$v) {_vm.location=$$v},expression:"location"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('date-picker-input',{attrs:{"label":_vm.$t('views.glaeubiger.uebergabe-history.date-from')},model:{value:(_vm.dateMin),callback:function ($$v) {_vm.dateMin=$$v},expression:"dateMin"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('date-picker-input',{attrs:{"label":_vm.$t('views.glaeubiger.uebergabe-history.date-to')},model:{value:(_vm.dateMax),callback:function ($$v) {_vm.dateMax=$$v},expression:"dateMax"}})],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.loading},on:{"click":_vm.search}},[_vm._v(" "+_vm._s(_vm.$t('views.glaeubiger.uebergabe-history.search'))+" ")])],1)],1),(_vm.pageCount > 1)?_c('div',{staticClass:"text-center mb-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":"10"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1):_vm._e(),_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dense":""}},[_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","color":"white"},on:{"click":function($event){_vm.$exporter.exportTableToPrinter(
            _vm.$refs.historyTable.$el,
            _vm.$t('views.glaeubiger.uebergabe-history.exportTableToPrinter'),
            _vm.exportInfoLines()
          )}}},[_c('v-icon',[_vm._v("mdi-printer")])],1),_c('v-btn',{attrs:{"small":"","color":"white"},on:{"click":function($event){_vm.$exporter.exportTableToExcel(
            _vm.$refs.historyTable.$el,
            _vm.$t('views.glaeubiger.uebergabe-history.exportTableToExcel'),
            _vm.exportInfoLines()
          )}}},[_c('v-icon',[_vm._v("mdi-microsoft-excel")])],1)],1),_c('v-data-table',{ref:"historyTable",attrs:{"headers":_vm.headers,"items":_vm.uebergaben,"item-key":"id","page":_vm.page,"items-per-page":_vm.itemsPerPage,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"server-items-length":_vm.totalNumber,"hide-default-footer":"","dense":"","loading":_vm.loading},on:{"update:page":function($event){_vm.page=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"header.glgNumber",fn:function(ref){
          var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"header.userId",fn:function(ref){
          var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"header.date",fn:function(ref){
          var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"header.time",fn:function(ref){
          var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"header.text",fn:function(ref){
          var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"item.date",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.date))+" ")]}}])})],1),(_vm.pageCount > 1)?_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":"10"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
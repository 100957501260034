import {Payload} from '@/api/payload';

export class Response<T extends Payload> {
  payload: T;
  version: string;
  error: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  constructor(type: any, body: {version: string; error: string; payload: any}) {
    this.version = body.version;
    this.error = body.error;
    this.payload = new type(body.payload);
  }
}























































































































































































































































































import {Component, Vue, Watch} from 'vue-property-decorator';
import {Forderung, SelectValueTextItem} from '@/store/model';
import DatePickerInput from '@/components/DatePickerInput.vue';
import GlgNumbersSelect from '@/components/glaeubiger/GlgNumbersSelect.vue';
import {ForderungenRequestPayload} from '@/api/request/v1/glaeubiger/forderungenRequestPayload';

import {formatNumber, formatDate} from '@/formatter';

@Component({
  components: {DatePickerInput, GlgNumbersSelect},
})
export default class Forderungen extends Vue {
  private readonly formatNumber = formatNumber;
  private readonly formatDate = formatDate;

  private geschaeftsId = '';
  private name = '';
  private invoiceNumber = '';
  private inkassoNumber = '';
  private inkassoNumberMin = '';
  private inkassoNumberMax = '';
  private hauptforderungMin = '';
  private hauptforderungMax = '';
  private dateMin = '';
  private dateMax = '';
  private type: string[] = [];
  private glgNumbers: string[] = [];

  private readonly itemsPerPage = 25;
  private loading = false;
  private totalNumber = 1;
  private sortBy = 'inkassoNumber';
  private sortDesc = false;
  private page = 1;
  private headers = [
    {
      text: 'views.glaeubiger.search.tableGlgNumber',
      value: 'glgNumber',
    },
    {text: 'views.glaeubiger.search.tableGlgId', value: 'geschaeftsId'},
    {
      text: 'views.glaeubiger.search.tableInkassoNumber',
      value: 'inkassoNumber',
    },
    {text: 'views.glaeubiger.search.tableName', value: 'name'},
    {text: 'views.glaeubiger.search.tableType', value: 'type'},
    {
      text: 'views.glaeubiger.search.tableHauptforderung',
      value: 'haupforderung',
      align: 'right',
    },
    {text: 'views.glaeubiger.search.tableDate', value: 'date'},
    {
      text: 'views.glaeubiger.search.tableSaldo',
      value: 'saldo',
      align: 'right',
    },
  ];
  private forderungen: Forderung[] = [];

  private search(): void {
    this.getDataFromApi();
  }

  private mounted(): void {
    this.type = this.aktenTypeItems.map((t) => t.value);
    this.getDataFromApi();
  }

  private get aktenTypeItems(): SelectValueTextItem<string>[] {
    if (this.$store.state.glaeubiger.user === undefined) {
      return [];
    }
    const userAktenTypes =
      this.$store.state.glaeubiger.user.userAktenTypes || [];
    const aktenTypes = this.$store.state.glaeubiger.user.aktenTypes || [];
    const aktenTypeNames =
      this.$store.state.glaeubiger.user.aktenTypeNames || [];
    return (
      aktenTypes
        .map((x: string, i: number) => {
          return {value: x, text: x + ' - ' + aktenTypeNames[i]};
        })
        // Filter all akten types by the user allowed akten types
        .filter((x: SelectValueTextItem<string>) => {
          return userAktenTypes.includes(x.value);
        })
    );
  }

  private toggleAktentypes() {
    this.$nextTick(() => {
      if (this.type.length == this.aktenTypeItems.length) {
        this.type = [];
      } else {
        this.type = this.aktenTypeItems.map((x) => x.value);
      }
    });
  }

  private getAktenTypeValue(type: {value: string}): string {
    return type.value;
  }

  @Watch('sortBy')
  @Watch('sortDesc')
  @Watch('page')
  private getDataFromApi(): void {
    this.loading = true;
    if (typeof this.sortBy === 'object') {
      this.sortBy = this.sortBy[0];
    }
    if (typeof this.sortDesc === 'object') {
      this.sortDesc = this.sortDesc[0];
    }
    const payload: ForderungenRequestPayload = {
      itemsPerPage: this.itemsPerPage,
      pageNumber: this.page,
      sortedBy: this.sortBy,
      sortedDesc: this.sortDesc,
      glgNumbers: [...this.glgNumbers],
      name: this.name,
      type: this.type,
      invoiceNumber: this.invoiceNumber,
      geschaeftsId: this.geschaeftsId,
      inkassoNumber: this.inkassoNumber,
      inkassoNumberMin: this.inkassoNumberMin,
      inkassoNumberMax: this.inkassoNumberMax,
      hauptforderungMin: this.hauptforderungMin,
      hauptforderungMax: this.hauptforderungMax,
      dateMin: this.dateMin,
      dateMax: this.dateMax,
    };
    this.$store
      .dispatch('glaeubiger/forderungen', payload)
      .then((response) => {
        this.totalNumber = response['payload']['totalNumberData'];
        this.forderungen = response['payload']['data'];
        this.loading = false;
      })
      .catch();
  }

  private openFallbetrachtung(value: Forderung): void {
    this.$router.push('/glg/forderungen/' + value.inkassoNumber);
  }

  private get pageCount(): number {
    return Math.ceil(this.totalNumber / this.itemsPerPage);
  }

  private exportInfoLines(): string[][] {
    return [
      [
        this.$t('views.glaeubiger.search.export-info-lines1').toString(),
        this.glgNumbers.join(', '),
      ],
      [
        this.$t('views.glaeubiger.search.export-info-lines2').toString(),
        this.$store.state.glaeubiger.user.customerName,
      ],
    ];
  }
}

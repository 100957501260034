



















































































































import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {Response} from '@/api/response/response';
import {SuccessResponsePayload} from '@/api/response/v1/glaeubiger/statistics/successResponsePayload';
import {formatNumber} from '@/formatter';
import DatePickerInput from '@/components/DatePickerInput.vue';

@Component({
  components: {DatePickerInput},
})
export default class StatisticSuccessCard extends Vue {
  @Prop({
    type: Array,
    required: true,
  })
  public readonly glgNumbers!: string[];

  private readonly formatNumber = formatNumber;
  private loading = false;
  private error = false;
  private readonly headers = [
    {text: '', value: 'type'},
    {
      text: this.$t(
        'views.glaeubiger.statistics.statistic-success.table-head-1'
      ).toString(),
      value: 'brutto',
      align: 'end',
      sortable: false,
      groupable: false,
    },
    {
      text: this.$t(
        'views.glaeubiger.statistics.statistic-success.table-head-2'
      ).toString(),
      value: 'netto',
      align: 'end',
      sortable: false,
      groupable: false,
    },
    {
      text: this.$t(
        'views.glaeubiger.statistics.statistic-success.table-head-3'
      ).toString(),
      value: 'number',
      align: 'end',
      groupable: false,
    },
    {
      text: this.$t(
        'views.glaeubiger.statistics.statistic-success.table-head-4'
      ).toString(),
      value: 'hfVolumen',
      align: 'end',
      groupable: false,
    },
    {
      text: this.$t(
        'views.glaeubiger.statistics.statistic-success.table-head-5'
      ).toString(),
      value: 'category',
      groupable: false,
    },
  ];

  private items: {
    type: string;
    number: number;
    hfVolumen: number;
    brutto: number;
    netto: number;
  }[] = [];
  private totalNumber = 0;
  private totalHFVolumen = 0;

  private itemsSpecific: {
    type: string;
    number: number;
    hfVolumen: number;
    brutto: number;
    netto: number;
    category: string;
  }[] = [];

  private dictNameToTranslation: {[key: string]: string} = {
    Erfolgreich: this.$t(
      'views.glaeubiger.statistics.statistic-success.table-1'
    ).toString(),
    Laufend: this.$t(
      'views.glaeubiger.statistics.statistic-success.table-2'
    ).toString(),
    Erfolglos: this.$t(
      'views.glaeubiger.statistics.statistic-success.table-3'
    ).toString(),
  };

  private dateMin = '';
  private dateMax = '';

  public mounted(): void {
    this.error = false;
  }

  private search(): void {
    this.getDataFromApi();
  }

  @Watch('glgNumbers')
  private getDataFromApi(): void {
    this.loading = true;
    this.error = false;
    this.totalNumber = 0;
    this.totalHFVolumen = 0;
    this.$store
      .dispatch('glaeubiger/statisticsSuccess', {
        glgNumbers: this.glgNumbers,
        dateMin: this.dateMin,
        dateMax: this.dateMax,
      })
      .then((response: Response<SuccessResponsePayload>) => {
        this.items = [];
        const generalStatistics = Object.keys(
          response.payload.generalStatistics
        );
        for (let i = 0; i < generalStatistics.length; i++) {
          const type = generalStatistics[i];
          const item = {
            type: type,
            number: response.payload.generalStatistics[type][0],
            hfVolumen: response.payload.generalStatistics[type][1],
            brutto: response.payload.generalStatistics[type][2] * 100.0,
            netto: response.payload.generalStatistics[type][3] * 100.0,
          };
          this.items.push(item);
          this.totalNumber += item.number;
          this.totalHFVolumen += item.hfVolumen;
        }
        this.itemsSpecific = [];
        const specificStatistics = Object.keys(
          response.payload.specificStatistics
        );
        for (let i = 0; i < specificStatistics.length; i++) {
          const type = specificStatistics[i];
          this.itemsSpecific.push({
            type: type,
            number: response.payload.specificStatistics[type][0],
            hfVolumen: response.payload.specificStatistics[type][1],
            brutto: 0, // not used
            netto: 0, // not used
            category: response.payload.specificStatistics[type][2],
          });
        }
      })
      .catch(() => (this.error = false))
      .finally(() => (this.loading = false));
  }

  private exportInfoLines(): string[][] {
    return [
      [
        this.$t('views.glaeubiger.statistics.glaubigerNumber').toString(),
        this.glgNumbers.join(', '),
      ],
      [
        this.$t('views.glaeubiger.statistics.glaubigerName').toString(),
        this.$store.state.glaeubiger.user.customerName,
      ],
    ];
  }
}

import {Module} from 'vuex';
import {postApi} from './utils';
import {Response} from '@/api/response/response';
import {Payload} from '@/api/payload';
import {
  GlaeubigerModuleState,
  LoginCredentialsGlaeubiger,
  UserGlaeubiger,
} from './model';
import {TakeoversQuantityRequestPayload} from '@/api/request/v1/glaeubiger/statistics/takeoversQuantityRequestPayload';
import {LoginRequestPayload} from '@/api/request/v1/glaeubiger/session/loginRequestPayload';
import {AuftragUebergabeRequestPayload} from '@/api/request/v1/glaeubiger/auftragUebergabeRequestPayload';
import {ForderungenRequestPayload} from '@/api/request/v1/glaeubiger/forderungenRequestPayload';
import {MeldungsHistoryRequestPayload} from '@/api/request/v1/glaeubiger/meldungsHistoryRequestPayload';
import {TakeoversQuantityResponsePayload} from '@/api/response/v1/glaeubiger/statistics/takeoversQuantityResponsePayload';
import {LoginResponsePayload} from '@/api/response/v1/glaeubiger/session/loginResponsePayload';
import {AuftragUebergabeResponsePayload} from '@/api/response/v1/glaeubiger/auftragUebergabeResponsePayload';
import {ForderungResponsePayload} from '@/api/response/v1/glaeubiger/forderungenResponsePayload';
import {FallbetrachtungResponsePayload} from '@/api/response/v1/glaeubiger/fallbetrachtungResponsePayload';
import {MeldungsHistoryResponsePayload} from '@/api/response/v1/glaeubiger/meldungsHistoryResponsePayload';
import {UebergabeHistoryRequestPayload} from '@/api/request/v1/glaeubiger/uebergabeHistoryRequestPayload';
import {UebergabeHistoryResponsePayload} from '@/api/response/v1/glaeubiger/uebergabeHistoryResponsePayload';
import {FileDownloadResponsePayload} from '@/api/response/v1/glaeubiger/fileDownloadResponsePayload';
import {NachmeldungStellungnahmenRequestPayload} from '@/api/request/v1/glaeubiger/nachmeldungStellungnahmenRequestPayload';
import {NachmeldungGutschriftenRequestPayload} from '@/api/request/v1/glaeubiger/nachmeldungGutschriftenRequestPayload';
import {NachmeldungZahlungenRequestPayload} from '@/api/request/v1/glaeubiger/nachmeldungZahlungenRequestPayload';
import {NachmeldungForderungenRequestPayload} from '@/api/request/v1/glaeubiger/nachmeldungForderungenRequestPayload';
import {TakeoversHFVolumeRequestPayload} from '@/api/request/v1/glaeubiger/statistics/takeoversHFVolumeRequestPayload';
import {TakeoversHFVolumeResponsePayload} from '@/api/response/v1/glaeubiger/statistics/takeoversHFVolumeResponsePayload';
import {PaymentsYearRequestPayload} from '@/api/request/v1/glaeubiger/statistics/paymentsYearRequestPayload';
import {PaymentsYearResponsePayload} from '@/api/response/v1/glaeubiger/statistics/paymentsYearResponsePayload';
import {DashboardRequestPayload} from '@/api/request/v1/glaeubiger/dashboardRequestPayload';
import {DashboardResponsePayload} from '@/api/response/v1/glaeubiger/dashboardResponsePayload';
import {FallbetrachtungRequestPayload} from '@/api/request/v1/glaeubiger/fallbetrachtungRequestPayload';
import {FallbetrachtungHistoryRequestPayload} from '@/api/request/v1/glaeubiger/fallbetrachtungHistoryRequestPayload';
import {FallbetrachtungHistoryResponsePayload} from '@/api/response/v1/glaeubiger/fallbetrachtungHistoryResponsePayload';
import {FallbetrachtungHauptforderungRequestPayload} from '@/api/request/v1/glaeubiger/fallbetrachtungHauptforderungRequestPayload';
import {FallbetrachtungHauptforderungResponsePayload} from '@/api/response/v1/glaeubiger/fallbetrachtungHauptforderungResponsePayload';
import {FallbetrachtungZahlungsHistoryRequestPayload} from '@/api/request/v1/glaeubiger/fallbetrachtungZahlungsHistoryRequestPayload';
import {FallbetrachtungZahlungsHistoryResponsePayload} from '@/api/response/v1/glaeubiger/fallbetrachtungZahlungsHistoryResponsePayload';
import {FileUploadRequestPayload} from '@/api/request/v1/glaeubiger/fileUploadRequestPayload';
import {ChangePasswordRequestPayload} from '@/api/request/v1/glaeubiger/session/changePasswordRequestPayload';
import {SuccessRequestPayload} from '@/api/request/v1/glaeubiger/statistics/successRequestPayload';
import {SuccessResponsePayload} from '@/api/response/v1/glaeubiger/statistics/successResponsePayload';
import {NewsRequestPayload} from '@/api/request/v1/glaeubiger/newsRequestPayload';
import {NewsResponsePayload} from '@/api/response/v1/glaeubiger/newsResponsePayload';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const glaeubigerModule: Module<GlaeubigerModuleState, any> = {
  namespaced: true,
  state: {
    user: undefined,
  },
  getters: {
    isAuthenticated: (state) => !!state.user && !!state.user.token,
  },
  mutations: {
    login(state: GlaeubigerModuleState, user: UserGlaeubiger): void {
      sessionStorage.setItem('session_glg', JSON.stringify(user));
      state.user = user;
    },
    logout(state: GlaeubigerModuleState): void {
      sessionStorage.removeItem('session_glg');
      state.user = undefined;
    },
  },
  actions: {
    init(context): void {
      if (sessionStorage.getItem('session_glg') !== null) {
        context.state.user = JSON.parse(
          sessionStorage.getItem('session_glg') || '{}'
        );
        if (context.state.user) {
          if (
            !context.state.user.customerNames ||
            context.state.user.customerNames.length <
              context.state.user.glgNumbers.length
          ) {
            context.state.user.customerNames =
              context.state.user.glgNumbers.map(() => '');
          }
        }
      }
    },
    async login(
      context,
      credentials: LoginCredentialsGlaeubiger
    ): Promise<void> {
      return new Promise((resolve, reject) => {
        const payload = new LoginRequestPayload();
        payload.username = credentials.username;
        payload.password = credentials.password;
        postApi(
          undefined,
          '/v1/glg/session/login',
          payload,
          LoginResponsePayload
        )
          .then((response: Response<Payload>) => {
            const user: UserGlaeubiger = {
              ...(response.payload as LoginResponsePayload),
            };
            user.name = credentials.username;
            context.commit('login', user);
            resolve();
          })
          .catch((error) => reject(error));
      });
    },
    async logout(context): Promise<void> {
      return new Promise((resolve) => {
        postApi(
          context.state.user?.token,
          '/v1/glg/session/logout',
          new Payload(),
          Payload
        )
          .then(() => {
            context.commit('logout');
            resolve();
          })
          .catch(() => {
            context.commit('logout');
            resolve();
          });
      });
    },
    async auftraguebergabe(
      context,
      payload: AuftragUebergabeRequestPayload
    ): Promise<Response<AuftragUebergabeResponsePayload>> {
      return postApi(
        context.state.user?.token,
        '/v1/glg/uebergabe/send',
        payload,
        AuftragUebergabeResponsePayload
      );
    },
    async uebergabenHistory(
      context,
      payload: UebergabeHistoryRequestPayload
    ): Promise<Response<UebergabeHistoryResponsePayload>> {
      return postApi(
        context.state.user?.token,
        '/v1/glg/uebergabe/history',
        payload,
        UebergabeHistoryResponsePayload
      );
    },
    async uebergabeStellungnahmen(
      context,
      payload: NachmeldungStellungnahmenRequestPayload
    ): Promise<Response<Payload>> {
      return postApi(
        context.state.user?.token,
        '/v1/glg/uebergabe/stellungnahmen',
        payload,
        Payload
      );
    },
    async uebergabeForderungen(
      context,
      payload: NachmeldungForderungenRequestPayload
    ): Promise<Response<Payload>> {
      return postApi(
        context.state.user?.token,
        '/v1/glg/uebergabe/forderungen',
        payload,
        Payload
      );
    },
    async uebergabeZahlungen(
      context,
      payload: NachmeldungZahlungenRequestPayload
    ): Promise<Response<Payload>> {
      return postApi(
        context.state.user?.token,
        '/v1/glg/uebergabe/zahlungen',
        payload,
        Payload
      );
    },
    async uebergabeGutschriften(
      context,
      payload: NachmeldungGutschriftenRequestPayload
    ): Promise<Response<Payload>> {
      return postApi(
        context.state.user?.token,
        '/v1/glg/uebergabe/gutschriften',
        payload,
        Payload
      );
    },
    async forderungen(
      context,
      payload: ForderungenRequestPayload
    ): Promise<Response<ForderungResponsePayload>> {
      return postApi(
        context.state.user?.token,
        '/v1/glg/forderungen',
        payload,
        ForderungResponsePayload
      );
    },
    async news(
      context,
      payload: NewsRequestPayload
    ): Promise<Response<NewsResponsePayload>> {
      return postApi(
        context.state.user?.token,
        '/v1/glg/news',
        payload,
        NewsResponsePayload
      );
    },
    async meldungenHistory(
      context,
      payload: MeldungsHistoryRequestPayload
    ): Promise<Response<MeldungsHistoryResponsePayload>> {
      return postApi(
        context.state.user?.token,
        '/v1/glg/meldungen',
        payload,
        MeldungsHistoryResponsePayload
      );
    },
    async forderung(
      context,
      payload: FallbetrachtungRequestPayload
    ): Promise<Response<FallbetrachtungResponsePayload>> {
      return postApi(
        context.state.user?.token,
        '/v1/glg/forderung',
        payload,
        FallbetrachtungResponsePayload
      );
    },
    async forderungHistory(
      context,
      payload: FallbetrachtungHistoryRequestPayload
    ): Promise<Response<FallbetrachtungHistoryResponsePayload>> {
      return postApi(
        context.state.user?.token,
        '/v1/glg/forderung/history',
        payload,
        FallbetrachtungHistoryResponsePayload
      );
    },
    async forderungHauptforderung(
      context,
      payload: FallbetrachtungHauptforderungRequestPayload
    ): Promise<Response<FallbetrachtungHauptforderungResponsePayload>> {
      return postApi(
        context.state.user?.token,
        '/v1/glg/forderung/hauptforderung',
        payload,
        FallbetrachtungHauptforderungResponsePayload
      );
    },
    async forderungZahlungsHistory(
      context,
      payload: FallbetrachtungZahlungsHistoryRequestPayload
    ): Promise<Response<FallbetrachtungZahlungsHistoryResponsePayload>> {
      return postApi(
        context.state.user?.token,
        '/v1/glg/forderung/zahlungs_history',
        payload,
        FallbetrachtungZahlungsHistoryResponsePayload
      );
    },
    async downloadFallHistorieAnhang(
      context,
      payload: {inkassoNumber: string; recno: number}
    ): Promise<Response<FileDownloadResponsePayload>> {
      return postApi(
        context.state.user?.token,
        '/v1/glg/forderungsaufstellung',
        payload,
        FileDownloadResponsePayload
      );
    },
    async statisticsTakeoversYearMonthQuantity(
      context,
      payload: TakeoversQuantityRequestPayload
    ): Promise<Response<TakeoversQuantityResponsePayload>> {
      return postApi(
        context.state.user?.token,
        '/v1/glg/statistics/takeovers/year_month_quantity',
        payload,
        TakeoversQuantityResponsePayload
      );
    },
    async statisticsTakeoversYearMonthHFVolume(
      context,
      payload: TakeoversHFVolumeRequestPayload
    ): Promise<Response<TakeoversHFVolumeResponsePayload>> {
      return postApi(
        context.state.user?.token,
        '/v1/glg/statistics/takeovers/year_month_hfvolume',
        payload,
        TakeoversHFVolumeResponsePayload
      );
    },
    async statisticsPaymentsYear(
      context,
      payload: PaymentsYearRequestPayload
    ): Promise<Response<PaymentsYearResponsePayload>> {
      return postApi(
        context.state.user?.token,
        '/v1/glg/statistics/payments/year',
        payload,
        PaymentsYearResponsePayload
      );
    },
    async statisticsSuccess(
      context,
      payload: SuccessRequestPayload
    ): Promise<Response<SuccessResponsePayload>> {
      return postApi(
        context.state.user?.token,
        '/v1/glg/statistics/success',
        payload,
        SuccessResponsePayload
      );
    },
    async dashboard(
      context,
      payload: DashboardRequestPayload
    ): Promise<Response<DashboardResponsePayload>> {
      return postApi(
        context.state.user?.token,
        '/v1/glg/dashboard',
        payload,
        DashboardResponsePayload
      );
    },
    async fileUpload(
      context,
      payload: FileUploadRequestPayload
    ): Promise<Response<Payload>> {
      return postApi(
        context.state.user?.token,
        '/v1/glg/uebergabe/upload',
        payload,
        Payload
      );
    },
    async changePassword(
      context,
      payload: ChangePasswordRequestPayload
    ): Promise<Response<Payload>> {
      return postApi(
        context.state.user?.token,
        '/v1/glg/session/password_change',
        payload,
        Payload
      );
    },
  },
};

export default glaeubigerModule;































































































import {Component, Vue} from 'vue-property-decorator';
import {ChangePasswordRequestPayload} from '@/api/request/v1/glaeubiger/session/changePasswordRequestPayload';

@Component
export default class PasswordChange extends Vue {
  private readonly passwordRules = [
    (v: string): string | true => !!v || 'Pflichtfeld',
  ];

  private showDialog = false;
  private sending = false;
  private sendFinished = false;
  private error = false;
  private valid = true;
  private newPassword = '';
  private newPasswordRepeat = '';

  private get passwordRepeatRules() {
    return [
      (v: string): string | true => !!v || 'Pflichtfeld',
      (v: string): string | true =>
        this.newPassword === v || 'Passwörter stimmen nicht überein',
    ];
  }

  public mounted(): void {
    this.showDialog = false;
    this.error = false;
    this.sending = false;
    this.sendFinished = false;
    this.valid = true;
    this.newPassword = '';
    this.newPasswordRepeat = '';
  }

  private get isFormValid(): boolean {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const form = this.$refs.form as any;
    return form && form.validate();
  }

  private send(): void {
    if (this.isFormValid) {
      const payload = new ChangePasswordRequestPayload();
      payload.newPassword = this.newPassword;
      this.showDialog = true;
      this.sending = true;
      this.sendFinished = false;
      this.$store
        .dispatch('glaeubiger/changePassword', payload)
        .then(() => {
          this.newPassword = '';
          this.newPasswordRepeat = '';
          this.error = false;
        })
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          this.sending = false;
          this.sendFinished = true;
        });
    }
  }
}

















































































































































































































































































































import {Component, Vue, Watch} from 'vue-property-decorator';
import {Meldung} from '@/store/model';
import DatePickerInput from '@/components/DatePickerInput.vue';
import GlgNumbersSelect from '@/components/glaeubiger/GlgNumbersSelect.vue';
import {MeldungsHistoryRequestPayload} from '@/api/request/v1/glaeubiger/meldungsHistoryRequestPayload';
import {formatNumber} from '@/formatter';

@Component({
  components: {DatePickerInput, GlgNumbersSelect},
})
export default class Meldungen extends Vue {
  private readonly formatNumber = formatNumber;

  private showDetailsDialog = false;
  private showDetailsMeldung: Meldung | null = null;
  private geschaeftsId = '';
  private userId = '';
  private inkassoNumber = '';
  private dateMin = '';
  private dateMax = '';
  private readonly typeItems = [
    {text: 'views.glaeubiger.meldungen.type-item-F', value: 'F'},
    {text: 'views.glaeubiger.meldungen.type-item-Z', value: 'Z'},
    {text: 'views.glaeubiger.meldungen.type-item-S', value: 'S'},
    {text: 'views.glaeubiger.meldungen.type-item-G', value: 'G'},
  ];
  private type = ['F', 'Z', 'S', 'G'];
  private glgNumbers: string[] = [];

  private readonly itemsPerPage = 25;
  private loading = false;
  private totalNumber = 1;
  private sortBy = 'glgNumber';
  private sortDesc = false;
  private page = 1;
  private headers = [
    {
      text: 'views.glaeubiger.meldungen.header-1',
      value: 'glgNumber',
    },
    {text: 'views.glaeubiger.meldungen.header-2', value: 'userId'},
    {text: 'views.glaeubiger.meldungen.header-3', value: 'date'},
    {
      text: 'views.glaeubiger.meldungen.header-4',
      value: 'time',
      sortable: false,
    },
    {text: 'views.glaeubiger.meldungen.header-5', value: 'inkassoNumber'},
    {text: 'views.glaeubiger.meldungen.header-6', value: 'geschaeftsId'},
    {text: 'views.glaeubiger.meldungen.header-7', value: 'type'},
    {
      text: 'views.glaeubiger.meldungen.header-8',
      value: 'betrag',
      align: 'end',
    },
    {text: '', value: 'icon', sortable: false},
  ];
  private meldungen: Meldung[] = [];

  private search(): void {
    this.getDataFromApi();
  }

  private mounted(): void {
    this.getDataFromApi();
  }

  private toggleMeldungstyp() {
    this.$nextTick(() => {
      if (this.type.length == this.typeItems.length) {
        this.type = [];
      } else {
        this.type = this.typeItems.map((x) => x.value);
      }
    });
  }

  @Watch('sortBy')
  @Watch('sortDesc')
  @Watch('page')
  private getDataFromApi(): void {
    this.loading = true;
    if (typeof this.sortBy == 'object') {
      this.sortBy = this.sortBy[0];
    }
    if (typeof this.sortDesc == 'object') {
      this.sortDesc = this.sortDesc[0];
    }
    const payload: MeldungsHistoryRequestPayload = {
      itemsPerPage: this.itemsPerPage,
      pageNumber: this.page,
      sortedBy: this.sortBy,
      sortedDesc: this.sortDesc,
      glgNumbers: [...this.glgNumbers],
      userId: this.userId,
      type: this.type,
      geschaeftsId: this.geschaeftsId,
      inkassoNumber: this.inkassoNumber,
      dateMin: this.dateMin,
      dateMax: this.dateMax,
    };
    this.$store
      .dispatch('glaeubiger/meldungenHistory', payload)
      .then((response) => {
        this.totalNumber = response['payload']['totalNumberData'];
        this.meldungen = response['payload']['data'];
      })
      .catch(() => {
        // nothing
      })
      .finally(() => {
        this.loading = false;
      });
  }

  private closeDetails(): void {
    this.showDetailsDialog = false;
    this.showDetailsMeldung = null;
  }

  private openDetails(value: Meldung): void {
    this.showDetailsDialog = true;
    this.showDetailsMeldung = value;
  }

  private get pageCount(): number {
    return Math.ceil(this.totalNumber / this.itemsPerPage);
  }

  private exportInfoLines(): string[][] {
    return [
      [
        this.$t('views.glaeubiger.meldungen.export-info-lines1').toString(),
        this.glgNumbers.join(', '),
      ],
      [
        this.$t('views.glaeubiger.meldungen.export-info-lines2').toString(),
        this.$store.state.glaeubiger.user.customerName,
      ],
    ];
  }

  private getTypeOutputText(type: string): string {
    switch (type.substring(0, 1).toUpperCase()) {
      case 'F':
        return 'Ford.Erhöh';
      case 'G':
        return 'Gutschriften';
      case 'Z':
        return 'Zahlungen';
      case 'S':
        return 'Stellungnahmen';
      default:
        return type;
    }
  }

  private formatDetailsContent(text: string): string {
    return text.replaceAll('¶', '\n');
  }
}

import {Payload} from '@/api/payload';

export class NewsResponsePayload extends Payload {
  text: string;
  newsExist: boolean;

  constructor(body: {text: string; newsExist: boolean}) {
    super();
    this.text = body.text;
    this.newsExist = body.newsExist;
  }
}

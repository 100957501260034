import {Payload} from '@/api/payload';
import {Uebergabe} from '@/store/model';

export class UebergabeHistoryResponsePayload extends Payload {
  currentPage: number;
  totalNumberData: number;
  data: Uebergabe[];

  constructor(body: {
    currentPage: number;
    totalNumberData: number;
    data: Uebergabe[];
  }) {
    super();
    this.currentPage = body.currentPage;
    this.totalNumberData = body.totalNumberData;
    this.data = body.data;
  }
}


































































































































































import {Component, Vue, Watch} from 'vue-property-decorator';
import {Uebergabe} from '@/store/model';
import DatePickerInput from '@/components/DatePickerInput.vue';
import GlgNumbersSelect from '@/components/glaeubiger/GlgNumbersSelect.vue';
import {UebergabeHistoryRequestPayload} from '@/api/request/v1/glaeubiger/uebergabeHistoryRequestPayload';
import {formatDate} from '@/formatter';

@Component({
  components: {DatePickerInput, GlgNumbersSelect},
})
export default class UebergabeHistory extends Vue {
  private readonly formatDate = formatDate;

  private userId = '';
  private dateMin = '';
  private dateMax = '';
  private readonly locationItems = [
    {text: 'views.glaeubiger.uebergabe-history.locationItems1', value: 'in'},
    {text: 'views.glaeubiger.uebergabe-history.locationItems2', value: 'aus'},
  ];
  private location = 'in';
  private glgNumbers: string[] = [];

  private readonly itemsPerPage = 25;
  private loading = false;
  private totalNumber = 1;
  // Default sort by date descending
  private sortBy = 'date';
  private sortDesc = true;
  private page = 1;
  private headers = [
    {
      text: 'views.glaeubiger.uebergabe-history.glgNumber',
      value: 'glgNumber',
    },
    {text: 'views.glaeubiger.uebergabe-history.user-id', value: 'userId'},
    {text: 'views.glaeubiger.uebergabe-history.date', value: 'date'},
    {
      text: 'views.glaeubiger.uebergabe-history.time',
      value: 'time',
      sortable: false,
    },
    {text: 'views.glaeubiger.uebergabe-history.text', value: 'text'},
  ];
  private uebergaben: Uebergabe[] = [];

  private search(): void {
    this.getDataFromApi();
  }

  private mounted(): void {
    this.getDataFromApi();
  }

  @Watch('sortBy')
  @Watch('sortDesc')
  @Watch('page')
  private getDataFromApi(): void {
    this.loading = true;
    if (typeof this.sortBy == 'object') {
      this.sortBy = this.sortBy[0];
    }
    if (typeof this.sortDesc == 'object') {
      this.sortDesc = this.sortDesc[0];
    }
    const payload: UebergabeHistoryRequestPayload = {
      itemsPerPage: this.itemsPerPage,
      pageNumber: this.page,
      sortedBy: this.sortBy,
      sortedDesc: this.sortDesc,
      glgNumbers: [...this.glgNumbers],
      userId: this.userId,
      location: this.location,
      dateMin: this.dateMin,
      dateMax: this.dateMax,
    };
    this.$store
      .dispatch('glaeubiger/uebergabenHistory', payload)
      .then((response) => {
        this.totalNumber = response['payload']['totalNumberData'];
        this.uebergaben = response['payload']['data'];
      })
      .catch(() => {
        // nothing
      })
      .finally(() => {
        this.loading = false;
      });
  }

  private get pageCount(): number {
    return Math.ceil(this.totalNumber / this.itemsPerPage);
  }

  private exportInfoLines(): string[][] {
    return [
      [
        this.$t(
          'views.glaeubiger.uebergabe-history.exportInfoLines1'
        ).toString(),
        this.glgNumbers.join(', '),
      ],
      [
        this.$t(
          'views.glaeubiger.uebergabe-history.exportInfoLines2'
        ).toString(),
        this.$store.state.glaeubiger.user.customerName,
      ],
      [
        this.$t(
          'views.glaeubiger.uebergabe-history.exportInfoLines3'
        ).toString(),
        this.location === 'aus'
          ? this.$t(
              'views.glaeubiger.uebergabe-history.exportInfoLines3-outland'
            ).toString()
          : this.$t(
              'views.glaeubiger.uebergabe-history.exportInfoLines3-inland'
            ).toString(),
      ],
    ];
  }
}































































































































import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {Response} from '@/api/response/response';
import {PaymentsYearResponsePayload} from '@/api/response/v1/glaeubiger/statistics/paymentsYearResponsePayload';
import {formatNumber} from '@/formatter';
import DatePickerInput from '@/components/DatePickerInput.vue';

@Component({
  components: {DatePickerInput},
})
export default class StatisticPaymentsYearCard extends Vue {
  @Prop({
    type: Array,
    required: true,
  })
  public readonly glgNumbers!: string[];

  private readonly formatNumber = formatNumber;
  private loading = false;
  private error = false;
  private readonly headers = [
    {
      text: this.$t(
        'views.glaeubiger.statistics.statistic-payments-year.table-1'
      ).toString(),
      value: 'yearMonth',
    },
    {
      text: this.$t(
        'views.glaeubiger.statistics.statistic-payments-year.table-2'
      ).toString(),
      value: 'total',
      align: 'end',
    },
    {
      text: this.$t(
        'views.glaeubiger.statistics.statistic-payments-year.table-3'
      ).toString(),
      value: 'paid',
      align: 'end',
    },
    {
      text: this.$t(
        'views.glaeubiger.statistics.statistic-payments-year.table-4'
      ).toString(),
      value: 'left',
      align: 'end',
    },
    {
      text: this.$t(
        'views.glaeubiger.statistics.statistic-payments-year.table-5'
      ).toString(),
      value: 'netto',
      align: 'end',
    },
  ];
  private items: {
    yearMonth: string;
    year: number;
    month: number | null;
    total: number;
    paid: number;
    left: number;
    netto: number;
  }[] = [];
  private total = {total: 0, paid: 0, left: 0, netto: 0};

  private dateMin = '';
  private dateMax = '';

  public mounted(): void {
    this.error = false;
  }

  private search(): void {
    this.getDataFromApi();
  }

  @Watch('glgNumbers')
  private getDataFromApi(): void {
    this.loading = true;
    this.error = false;
    this.$store
      .dispatch('glaeubiger/statisticsPaymentsYear', {
        glgNumbers: this.glgNumbers,
        dateMin: this.dateMin,
        dateMax: this.dateMax,
      })
      .then((response: Response<PaymentsYearResponsePayload>) => {
        this.items = [];
        this.total = {total: 0, paid: 0, left: 0, netto: 0};
        const years = Object.keys(response.payload.years);
        for (let i = 0; i < years.length; i++) {
          const key = years[i];
          const keyParts = key.split('-');
          const year = parseInt(keyParts[0]);
          const month = keyParts.length > 1 ? parseInt(keyParts[1]) : null;
          const totalHF = response.payload.years[key][0];
          const item = {
            year: year,
            month: month,
            yearMonth: key,
            total: totalHF,
            paid: response.payload.years[key][1],
            left: response.payload.years[key][2],
            netto:
              totalHF <= 0
                ? 0
                : (response.payload.years[key][1] / totalHF) * 100.0,
          };
          this.items.push(item);
          if (month === null) {
            this.total.total += item.total;
            this.total.paid += item.paid;
            this.total.left += item.left;
          }
        }
        this.total.netto =
          this.total.total <= 0
            ? 0
            : (this.total.paid / this.total.total) * 100.0;
      })
      .catch(() => (this.error = false))
      .finally(() => (this.loading = false));
  }

  private exportInfoLines(): string[][] {
    return [
      [
        this.$t('views.glaeubiger.statistics.glaubigerNumber').toString(),
        this.glgNumbers.join(', '),
      ],
      [
        this.$t('views.glaeubiger.statistics.glaubigerName').toString(),
        this.$store.state.glaeubiger.user.customerName,
      ],
    ];
  }
}

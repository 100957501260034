





























































import {NachmeldungGutschriftenRequestPayload} from '@/api/request/v1/glaeubiger/nachmeldungGutschriftenRequestPayload';
import GutschriftenInput from '@/components/glaeubiger/GutschriftenInput.vue';
import {UebergabeGutschrift} from '@/store/model';
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  components: {GutschriftenInput},
})
export default class NachmeldungForderungenDialog extends Vue {
  @Prop({
    type: Boolean,
    required: true,
  })
  public readonly value!: boolean;
  @Prop({
    type: String,
    required: true,
  })
  public readonly inkassoNumber!: string;
  private items: UebergabeGutschrift[] = [];
  private sending = false;
  private sendFinished = false;
  private error = false;
  private valid = true;

  private get isOpen(): boolean {
    return this.value;
  }

  private set isOpen(newValue: boolean) {
    this.$emit('input', newValue);
  }

  private close(): void {
    this.items = [];
    this.isOpen = false;
    this.error = false;
    this.sending = false;
    this.sendFinished = false;
  }

  private get isFormValid(): boolean {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const form = this.$refs.form as any;
    return form && form.validate();
  }

  private send(): void {
    if (this.isFormValid) {
      const payload = new NachmeldungGutschriftenRequestPayload();
      payload.inkassoNumber = this.inkassoNumber;
      payload.gutschriften = [...this.items];
      this.sending = true;
      this.sendFinished = false;
      this.$store
        .dispatch('glaeubiger/uebergabeGutschriften', payload)
        .then(() => {
          this.items = [];
          this.error = false;
        })
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          this.sending = false;
          this.sendFinished = true;
        });
    }
  }
}


































import GlgNumbersSelect from '@/components/glaeubiger/GlgNumbersSelect.vue';
import StatisticTakeoversYearMonthQuantityCard from '@/components/glaeubiger/statistics/StatisticTakeoversYearMonthQuantityCard.vue';
import StatisticTakeoversYearMonthHFVolumeCard from '@/components/glaeubiger/statistics/StatisticTakeoversYearMonthHFVolumeCard.vue';
import StatisticPaymentsYearCard from '@/components/glaeubiger/statistics/StatisticPaymentsYearCard.vue';
import StatisticSuccessCard from '@/components/glaeubiger/statistics/StatisticSuccessCard.vue';
import {Component, Vue} from 'vue-property-decorator';

@Component({
  components: {
    GlgNumbersSelect,
    StatisticTakeoversYearMonthQuantityCard,
    StatisticTakeoversYearMonthHFVolumeCard,
    StatisticPaymentsYearCard,
    StatisticSuccessCard,
  },
})
export default class Statistics extends Vue {
  private glgNumbers: string[] = [];
  private glgNumbersStats: string[] = [];

  public mounted(): void {
    this.$nextTick(() => this.updateStatistics());
  }

  private updateStatistics(): void {
    // TODO
    this.glgNumbersStats = [...this.glgNumbers];
  }
}


































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import DatePickerInput from '@/components/DatePickerInput.vue';
import {UebergabeForderung} from '@/store/model';
import {
  RequiredDateValidationRules,
  RequiredNumberValidationRules,
  RequiredStringValidationRules,
} from '@/formatter';

@Component({
  components: {DatePickerInput},
})
export default class ForderungenUebergabeInput extends Vue {
  @Prop({
    type: Array,
    required: true,
    default: [],
  })
  public readonly forderungen!: UebergabeForderung[];
  private readonly amountRules = RequiredNumberValidationRules;
  private readonly dateRules = RequiredDateValidationRules;
  private readonly textRules = RequiredStringValidationRules;

  private add(): void {
    this.forderungen.push({
      invoiceSum: '',
      reminderFees: '',
      bankCosts: '',
      invoiceNumber: '',
      invoiceDate: '',
      contractDate: '',
      firstReminderDate: '',
    });
  }

  private remove(i: number): void {
    if (this.forderungen.length > 1) {
      this.forderungen.splice(i, 1);
    }
  }
}

import Vue from 'vue';
import VueI18n, {
  Locale,
  LocaleMessageObject,
  LocaleMessages,
  Path,
} from 'vue-i18n';

Vue.use(VueI18n);

function loadMessages(): {
  context: __WebpackModuleApi.RequireContext;
  messages: {[x: string]: LocaleMessageObject};
} {
  const context = require.context('@/locales', true, /[a-z0-9-_]+\.json$/i);
  const messages: LocaleMessages = {};
  context.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = context(key);
    }
  });
  return {context, messages};
}

const {context, messages} = loadMessages();

const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'de',
  silentTranslationWarn: true,
  missing: (_: Locale, key: Path) => {
    return key.toString();
  },
  messages,
});

if (module.hot) {
  module.hot.accept(context.id, () => {
    const {messages: newMessages} = loadMessages();
    Object.keys(newMessages)
      .filter((locale) => messages[locale] !== newMessages[locale])
      .forEach((locale) => {
        messages[locale] = newMessages[locale];
        i18n.setLocaleMessage(locale, messages[locale]);
      });
  });
}

export default i18n;

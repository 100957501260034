import {Payload} from '@/api/payload';

export class FallbetrachtungZahlungsHistoryResponsePayload extends Payload {
  history: FallZahlungsHistoryEntrySubpayload[];

  constructor(body: {history: FallZahlungsHistoryEntrySubpayload[]}) {
    super();
    this.history = body.history;
  }
}

export class FallZahlungsHistoryEntrySubpayload {
  index: number;
  datum: string;
  text: string;
  betrag: string;
  buchart: string;
  bucharttxt: string;

  constructor() {
    this.index = 0;
    this.datum = '';
    this.text = '';
    this.betrag = '';
    this.buchart = '';
    this.bucharttxt = '';
  }
}



























































import {Component, Vue} from 'vue-property-decorator';

@Component
export default class GlgDrawerNavigation extends Vue {
  private readonly items: {label: string; icon: string; to: string}[] = [
    {
      label: 'views.glaeubiger.dashboard.nav-title',
      icon: 'mdi-home',
      to: '/glg/',
    },
    {
      label: 'views.glaeubiger.forderungen.nav-title',
      icon: 'mdi-text-box-search-outline',
      to: '/glg/forderungen',
    },
    {
      label: 'views.glaeubiger.message-history.nav-title',
      icon: 'mdi-text-box-search-outline',
      to: '/glg/meldungen',
    },
    {
      label: 'views.glaeubiger.mandate-transfer.nav-title',
      icon: 'mdi-send',
      to: '/glg/auftragsuebergabe',
    },
    {
      label: 'views.glaeubiger.mandate-transfer-history.nav-title',
      icon: 'mdi-text-box-search-outline',
      to: '/glg/uebergabe-history',
    },
    {
      label: 'views.glaeubiger.file-upload.nav-title',
      icon: 'mdi-upload',
      to: '/glg/upload',
    },
    {
      label: 'views.glaeubiger.statistics.nav-title',
      icon: 'mdi-chart-areaspline',
      to: '/glg/statistics',
    },
    {
      label: 'views.glaeubiger.pwchange.nav-title',
      icon: 'mdi-account-key',
      to: '/glg/pwchange',
    },
  ];

  private dialog = false;
}























import {Component, Vue} from 'vue-property-decorator';

@Component
export default class Glossar extends Vue {
  private readonly headers = [
    {text: 'Name', value: 'key'},
    {text: 'Beschreibung', value: 'description'},
  ];
  private readonly items: {key: string; description: string}[] = [
    {key: 'wort1', description: 'Ein Wort'},
    {key: 'wort2', description: 'Noch ein Wort'},
  ];
  private search = '';
}











































































































































































import {Component, Vue, Watch} from 'vue-property-decorator';
import GlgDrawerNavigation from '@/components/glaeubiger/GlgDrawerNavigation.vue';
import CookieDialog from '@/components/CookieDialog.vue';

@Component({
  components: {GlgDrawerNavigation, CookieDialog},
})
export default class App extends Vue {
  private isDrawerOpen = false;
  private isInitialized = false;
  private isCookieDialogOpen = false;


  public get privacyRoute(): string {
    return '/privacy';
  }

  public get imprintRoute(): string {
    return '/imprint';
  }

  public get loginRoute(): string {
    return '/login';
  }


  private get versionNumber(): string {
    if (process && process.env && process.env.VUE_APP_VERSION) {
      return 'v' + process.env.VUE_APP_VERSION;
    }
    return '';
  }

  private async created(): Promise<void> {
    this.isDrawerOpen = !this.$vuetify.breakpoint.mobile;
    document.title = this.$t('app.title').toString();
    await this.$store.dispatch('init');
    await this.$store.dispatch('glaeubiger/init');
    this.isInitialized = true;
    this.onRouteChanged();
  }

  public mounted(): void {
    this.isCookieDialogOpen = localStorage.getItem('cookieAccept') !== '1';
    this.$root.$on(
      'closedCookiesDialog',
      () => (this.isCookieDialogOpen = false)
    );
    this.$root.$on('changeCookies', () => (this.isCookieDialogOpen = true));
  }

  @Watch('$route')
  private onRouteChanged(): void {
    const currentPath = this.$router.currentRoute.path;
    if (
      currentPath != '/login' &&
      currentPath != '/glg/login' &&
      currentPath != '/glg/privacy' &&
      currentPath != '/glg/imprint' &&
      currentPath != '/imprint' &&
      currentPath != '/privacy'
    ) {
      if (this.$store.getters['glaeubiger/isAuthenticated']) {
        // If we are logged in as a glaeubiger but try to access a path
        // outside the glaeubiger scope, force logout
        if (!currentPath.startsWith('/glg')) {
          this.logout();
        }
      } else {
        this.$router.push('/login');
      }
    }
  }

  private changeLanguage(languageKey: string): void {
    this.$store.dispatch('changeLanguage', languageKey);
    this.$i18n.locale = languageKey;
  }

  private logout(): void {
    if (this.$store.getters['glaeubiger/isAuthenticated']) {
      this.$store
        .dispatch('glaeubiger/logout')
        .finally(() => this.$router.push('/glg/login'));
    } else {
      this.$router.push('/login');
    }
  }
}

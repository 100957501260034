import {Payload} from '@/api/payload';
import {Request} from '@/api/request/request';
import {Response} from '@/api/response/response';
import axios from 'axios';

const apiUrl = process.env.VUE_APP_API_URL;

export function postApi<T1 extends Payload, T2 extends Payload>(
  token: string | undefined,
  path: string,
  payload: T1,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  responsePayloadType: any
): Promise<Response<T2>> {
  const request = new Request<T1>(payload);
  return new Promise((resolve, reject) => {
    const config = {
      headers: token && !!token ? {Authorization: 'Bearer ' + token} : {},
    };
    axios
      .post(apiUrl + path, request, config)
      .then((response) =>
        resolve(new Response<T2>(responsePayloadType, response.data))
      )
      .catch(reject);
  });
}

import i18n from './i18n';

export const RequiredNumberValidationRules = [
  (v: string): string | true =>
    !!v || i18n.t('rules.required-field').toString(),
  (v: string): string | true => {
    const num = v.replace(',', '.');
    return (
      (!isNaN(Number(num)) && !isNaN(parseFloat(num))) ||
      i18n.t('rules.formatting').toString()
    );
  },
];

export const DateRegex = /(0[1-9]|[12]\d|3[01]).(0[1-9]|1[0-2]).([12][0-9]{3})/;

export const RequiredDateValidationRules = [
  (v: string): string | true =>
    !!v || i18n.t('rules.required-field').toString(),
  (v: string): string | true =>
    DateRegex.test(v) || i18n.t('rules.format-error').toString(),
];

export const DateValidationRules = [
  (v: string): string | true =>
    v === null ||
    v.length === 0 ||
    DateRegex.test(v) ||
    i18n.t('rules.format-error').toString(),
];

export const RequiredStringValidationRules = [
  (v: string): string | true =>
    !!v || i18n.t('rules.required-field').toString(),
];

export const RequiredBooleanValidationRules = [
  (v: boolean): string | true =>
    !!v || i18n.t('rules.required-field').toString(),
];

export const OptionalIBANValidationRules = [
  (v: string): string | true =>
    v === null || v.length === 0 || isValidIBAN(v) || 'Falsche IBAN',
];

export function isValidIBAN(iban: string): boolean {
  // Ignore validation for all non-DE IBAN numbers
  if (iban.length >= 2 && iban.substring(0, 2) !== 'DE') {
    return true;
  }
  // iban can not be longer then 34 character, minimum is not fix but each iban need to have 2 letters for country and two check digits
  /*if (iban.length > 34 || iban.length < 4) {
    return false;
  }*/
  if (iban.length != 22) {
    return false;
  }
  const takeFirstFourLetters = iban.substring(0, 4).toLowerCase();
  // todo also in the validation string can be letter in other contries then germany
  const validationNumberString =
    transformStringWithLetterAndNumberToOnlyNumbers(
      iban.substring(4, iban.length) + takeFirstFourLetters
    );
  const validationNumber = BigInt(validationNumberString);
  return Number(validationNumber % BigInt(97)) === 1;
}

export function transformStringWithLetterAndNumberToOnlyNumbers(
  str: string
): string {
  let StringWIthOnlyNumbers = '';
  for (let position = 0; position < str.length; position++) {
    if (str.charCodeAt(position) > 57) {
      // get character postion in alphabet
      let characterNumber = str.charCodeAt(position) - 96;
      // for check all position are added with 9
      characterNumber += 9;
      StringWIthOnlyNumbers += characterNumber.toString();
    } else {
      StringWIthOnlyNumbers = StringWIthOnlyNumbers.concat(
        str.charAt(position)
      );
    }
  }
  return StringWIthOnlyNumbers;
}

export function formatNumber(value?: string | number): string {
  if (value === undefined || value === null) {
    return '-';
  }

  if (typeof value === 'number') {
    value = (Math.round((value + Number.EPSILON) * 100) / 100).toFixed(2);
  }
  const indexOfLastCommaOrDecimal = Math.max(
    value.lastIndexOf(','),
    value.lastIndexOf('.')
  );
  if (indexOfLastCommaOrDecimal === -1) {
    value = value + ',00';
  } else {
    value =
      value
        .substring(0, indexOfLastCommaOrDecimal)
        .replace(',', '')
        .replace('.', '') +
      ',' +
      value.substring(indexOfLastCommaOrDecimal + 1);
  }
  for (
    let i = value.indexOf(',') - 3;
    i > 0 && value.charAt(i - 1) !== '-';
    i -= 3
  ) {
    value = value.substring(0, i) + '.' + value.substring(i);
  }
  return value;
}

export function formatDate(date?: string): string {
  if (date === undefined || date === null) {
    return '-';
  }
  // change date from year-month-day to day.month.year
  const parts = date.split('-');
  if (parts.length === 3) {
    return parts[2] + '.' + parts[1] + '.' + parts[0];
  }
  return date;
}














import {SelectValueTextItem, UserGlaeubiger} from '@/store/model';
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class GlgNumbersSelect extends Vue {
  @Prop({
    type: Array,
    required: true,
  })
  public readonly value!: string[];
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  public readonly single!: boolean;

  private get glgNumbers(): string[] {
    return this.value;
  }

  private set glgNumbers(newValue: string[]) {
    this.$emit('input', newValue || '');
  }

  private get user(): UserGlaeubiger | undefined {
    return this.$store.state.glaeubiger.user;
  }

  private get glgNumberItems(): SelectValueTextItem<string[]>[] {
    if (this.user && this.user.glgNumbers) {
      const allGlgNumbers = [...this.user.glgNumbers];
      if (allGlgNumbers.length === 1) {
        return [
          {
            text: allGlgNumbers[0] + ' - ' + this.user.customerName,
            value: [allGlgNumbers[0]],
          },
        ];
      }
      const allItem = [
        {
          text: this.$t(
            'components.glg-number-select.all-item-text'
          ).toString(),
          value: allGlgNumbers,
        },
      ];
      const allCustomerNames = [...this.user.customerNames];
      while (allCustomerNames.length < allGlgNumbers.length) {
        allCustomerNames.push('');
      }
      const singleItems = allGlgNumbers.map((x: string, i: number) => {
        return {text: x + ' - ' + allCustomerNames[i], value: [x]};
      });
      return this.single ? singleItems : allItem.concat(singleItems);
    }
    return [];
  }

  private get disabled(): boolean {
    return (
      !this.user || !this.user.glgNumbers || this.user.glgNumbers.length < 2
    );
  }

  mounted(): void {
    if (this.user && this.user.glgNumbers) {
      const allGlgNumbers = [...this.user.glgNumbers];
      if (this.single) {
        this.$emit('input', [allGlgNumbers[0]]);
      } else {
        this.$emit('input', allGlgNumbers);
      }
    }
  }
}

import {Payload} from '@/api/payload';

export class TakeoversQuantityResponsePayload extends Payload {
  years: {
    [year: number]: [
      number, // january
      number, // february
      number, // march
      number, // april
      number, // may
      number, // june
      number, // july
      number, // august
      number, // september
      number, // october
      number, // november
      number // december
    ];
  } = {};

  constructor(payload: {
    years: {
      [year: number]: [
        number, // january
        number, // february
        number, // march
        number, // april
        number, // may
        number, // june
        number, // july
        number, // august
        number, // september
        number, // october
        number, // november
        number // december
      ];
    };
  }) {
    super();
    this.years = payload.years;
  }
}

import {Payload} from '@/api/payload';

export class FileDownloadResponsePayload extends Payload {
  data: string;
  dataType: string;
  filename: string;

  constructor(body: {data: string; dataType: string; filename: string}) {
    super();
    this.data = body.data;
    this.dataType = body.dataType;
    this.filename = body.filename;
  }
}

import {Payload} from '@/api/payload';

export class NachmeldungStellungnahmenRequestPayload extends Payload {
  inkassoNumber = '';
  text = '';

  constructor() {
    super();
  }
}




















import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class CountryCodeInput extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  public readonly value!: string;
  @Prop({
    type: String,
    required: false,
  })
  public readonly label?: string;
  @Prop({
    type: Array,
    required: false,
    default: () => [],
  })
  public readonly rules?: {(v: string): string | true}[];
  private readonly countryLookup = {
    A: 'Österreich',
    AFG: 'Afghanistan',
    AJ: 'Amerikanische Jungferninseln',
    AL: 'Albanien',
    AND: 'Andorra',
    ANG: 'Angola',
    AQU: 'Äquatorialguinea',
    ARM: 'Armenien',
    AS: 'Amerik. Samoa',
    AT: 'Antarktis Territorium',
    AUS: 'Australien',
    AX: 'Aland',
    AXA: 'Anguilla',
    AZ: 'Aserbaidschan',
    B: 'Belgien',
    BD: 'Bangladesh',
    BDA: 'Bermuda',
    BDS: 'Barbados',
    BF: 'Burkina Faso',
    BG: 'Bulgarien',
    BH: 'Belize',
    BIH: 'Bosnien-Herzegowina',
    BJ: 'Benin',
    BOL: 'Bolivien',
    BR: 'Brasilien',
    BRN: 'Bahrain',
    BRU: 'Brunei Darussalam',
    BTN: 'Bhutan',
    BS: 'Burma',
    BUR: 'Bahamas',
    BY: 'Belarus (Weißrußland)',
    C: 'Kuba',
    CAY: 'Cayman',
    CC: 'konsularischer Vertretungen',
    CD: 'diplomatischer Vertretungen',
    CAM: 'Kamerun',
    CDN: 'Kanada',
    CGO: 'Montenegro',
    CG: 'Demokratische Republik Kongo',
    CH: 'Schweiz',
    CI: 'Elfenbeinküste',
    CL: 'Sri Lanka',
    CN: 'Volksrep. China',
    CNB: 'Sabah u. Labuan',
    CO: 'Kolumbien',
    COI: 'Cook-Inseln',
    COM: 'Komoren',
    CR: 'Costa Rica',
    CU: 'Curacao',
    CUE: 'Canton und Enderbury',
    CY: 'Zypern',
    CV: 'Kap Verde',
    CZ: 'Tschechische Rep.',
    D: 'Deutschland',
    DJI: 'Dschibuti',
    DK: 'Dänemark',
    DOM: 'Dominik. Republik',
    DY: 'Benin',
    DZ: 'Algerien',
    E: 'Spanien',
    EAK: 'Kenia',
    EAT: 'Tansania',
    EAU: 'Uganda',
    EAZ: 'Zanzibar',
    EC: 'Ecuador',
    EGU: 'Aequatorial Guinea',
    ERI: 'Eritrea',
    ES: 'El Salvador',
    EST: 'Estland',
    ET: 'Ägypten',
    ETH: 'Äthiopien',
    F: 'Frankreich',
    FAL: 'Falklandinseln',
    FG: 'Franz. Guayana',
    FIN: 'Finnland',
    FJI: 'Fidschi',
    FL: 'Liechtenstein',
    FR: 'Färöer',
    FP: 'Franz. Polynesien',
    FSM: 'Mikronesien',
    GAB: 'Gabun',
    GB: 'Großbritannien',
    GBA: 'Insel Alderney',
    GBG: 'Insel Guernsey',
    GBJ: 'Insel Jersey',
    GBM: 'Insel Man',
    GBZ: 'Gibraltar',
    GCA: 'Guatemala',
    GE: 'Georgien',
    GH: 'Ghana',
    GIB: 'Gibraltar',
    GNB: 'Guinea',
    GR: 'Griechenland',
    GUA: 'Guadeoupe',
    GUI: 'Guinea-Bissau',
    GUM: 'Guam',
    GUY: 'Guyana',
    H: 'Ungarn',
    HEL: 'St. Helena einschl. Ascension',
    HK: 'Hongkong',
    HN: 'Honduras',
    HR: 'Kroatien',
    I: 'Italien',
    IL: 'Israel',
    IND: 'Indien',
    IR: 'Iran',
    IRL: 'Irland',
    IRQ: 'Irak',
    IS: 'Island',
    J: 'Japan',
    JA: 'Jamaika',
    JOR: 'Jordanien',
    K: 'Kambodscha',
    KAI: 'Kaimaninseln',
    KAN: 'Kanalinseln',
    KIB: 'Kiribati',
    KN: 'Grönland (Kalaallit Nunaat)',
    KP: 'Nordkorea',
    KS: 'Kirgisistan',
    KSA: 'Königreich Saudi Arabien',
    KWT: 'Kuwait',
    KZ: 'Kasachstan',
    L: 'Luxemburg',
    LAO: 'Laos',
    LAR: 'Libyen',
    LB: 'Liberia',
    LS: 'Lesotho',
    LT: 'Litauen',
    LV: 'Lettland',
    M: 'Malta',
    MA: 'Marokko',
    MAC: 'Macao',
    MAL: 'Malaysia',
    MAN: 'Irak',
    MH: 'Marshallinseln',
    MAT: 'Martinique',
    MAY: 'Mayotte',
    MC: 'Monaco',
    MD: 'Moldawien',
    MDV: 'Malediven',
    MGL: 'Mongolei',
    MEX: 'Mexiko',
    MIK: 'Mikronesien',
    MK: 'Mazedonien',
    MNE: 'Montenegro',
    MOC: 'Mozambique',
    MOT: 'Montserrat',
    MS: 'Mauritius',
    MW: 'Malawi',
    MYA: 'Myanmar',
    N: 'Norwegen',
    NA: 'Niederländische Antillen',
    NAM: 'Namibia',
    NAU: 'Nauru',
    NEP: 'Nepal',
    NIC: 'Nicaragua',
    NIU: 'Niue',
    NKA: 'Neukaledonien',
    NL: 'Niederlande',
    NMA: 'Nöördliche Marianen',
    NZ: 'Neuseeland',
    O: 'intern. Organisationen',
    OM: 'Oman',
    P: 'Portugal',
    PA: 'Panama',
    PAL: 'Palau',
    PE: 'Peru',
    PIE: 'ST. Pierre u. Miquelon',
    PIN: 'Pazifische Inseln',
    PIT: 'Pitcaim Inseln',
    PK: 'Pakistan',
    PL: 'Polen',
    PRI: 'Puerto Rico',
    PNG: 'Papua Neuguinea',
    PR: 'Puerto Rico',
    PRK: 'Nordkorea',
    PY: 'Paraguay',
    Q: 'Katar',
    RA: 'Argentinien',
    RB: 'Botswana',
    RC: 'Taiwan (China)',
    RCA: 'Zentralafrika',
    RCB: 'Kongo',
    RCH: 'Chile',
    RDU: 'Uruguay',
    RH: 'Haiti',
    REU: 'Reunion',
    RG: 'Guinea',
    RI: 'Indonesien',
    RIM: 'Mauretanien',
    RKS: 'Republik Kosovo',
    RL: 'Libanon',
    RM: 'Madagaskar',
    RMM: 'Mali',
    RN: 'Niger',
    RO: 'Rumänien',
    ROK: 'Rep. Korea (Süd)',
    ROU: 'Uruguay',
    RP: 'Philippinen',
    RSM: 'San Marino',
    RT: 'Togo',
    RU: 'Burundi',
    RUS: 'Rußland u.a.',
    RWA: 'Ruanda',
    S: 'Schweden',
    SA: 'Saudiarabien',
    SAL: 'Salomonen',
    SCN: 'St. Kitta und Nevis',
    SCV: 'Vatikan',
    SD: 'Swasiland',
    SGP: 'Singapur',
    SK: 'Slowakische Rep.',
    SLO: 'Slowenien',
    SME: 'Surinam',
    SN: 'Senegal',
    SP: 'Somalia',
    SRB: 'Serbien',
    STP: 'Sao Tome und Principe',
    SU: 'Sowjetunion (ehemalige)',
    SUD: 'Sudan',
    SWA: 'Namibia',
    SY: 'Seychellen',
    SYR: 'Syrien',
    T: 'Thailand',
    TD: 'Tschad',
    TG: 'Tonga',
    TJ: 'Tadschikistan',
    TM: 'Turkmenistan',
    TN: 'Tunesien',
    TOK: 'Tokelau Inseln',
    TR: 'Türkei',
    TT: 'Trinidad, Tobago',
    TUC: 'Turks- und Caicosinseln',
    TUV: 'Tuvalu',
    UA: 'Ukraine',
    UAE: 'Verein. Arab. Emirate',
    USA: 'Verein. Staaten',
    UZ: 'Usbekistan',
    V: 'Vatikanstadt',
    VAN: 'Vanatua',
    VG: 'Brit. Jungferninseln',
    VN: 'Vietnam',
    WAG: 'Gambia',
    WAL: 'Sierra Leone',
    WAN: 'Nigeria',
    WD: 'Dominika',
    WG: 'Grenada',
    WL: 'St. Lucia',
    WS: 'Westsamoa',
    WV: 'St. Vincent',
    YAR: 'Yemen',
    YV: 'Venezuela',
    Z: 'Sambia',
    ZA: 'Südafrika',
    ZRE: 'Zaire',
    ZW: 'Simbabwe',
    IDN: 'Indonesien',
  };
  private readonly items = [
    'A',
    'AFG',
    'AJ',
    'AL',
    'AND',
    'ANG',
    'AQU',
    'ARM',
    'AS',
    'AT',
    'AUS',
    'AX',
    'AXA',
    'AZ',
    'B',
    'BD',
    'BDA',
    'BDS',
    'BF',
    'BG',
    'BH',
    'BIH',
    'BJ',
    'BOL',
    'BR',
    'BRN',
    'BRU',
    'BTN',
    'BS',
    'BUR',
    'BY',
    'C',
    'CAY',
    'CC',
    'CD',
    'CAM',
    'CDN',
    'CGO',
    'CG',
    'CH',
    'CI',
    'CL',
    'CN',
    'CNB',
    'CO',
    'COI',
    'COM',
    'CR',
    'CU',
    'CUE',
    'CY',
    'CV',
    'CZ',
    'D',
    'DJI',
    'DK',
    'DOM',
    'DY',
    'DZ',
    'E',
    'EAK',
    'EAT',
    'EAU',
    'EAZ',
    'EC',
    'EGU',
    'ERI',
    'ES',
    'EST',
    'ET',
    'ETH',
    'F',
    'FAL',
    'FG',
    'FIN',
    'FJI',
    'FL',
    'FR',
    'FP',
    'FSM',
    'GAB',
    'GB',
    'GBA',
    'GBG',
    'GBJ',
    'GBM',
    'GBZ',
    'GCA',
    'GE',
    'GH',
    'GIB',
    'GNB',
    'GR',
    'GUA',
    'GUI',
    'GUM',
    'GUY',
    'H',
    'HEL',
    'HK',
    'HN',
    'HR',
    'I',
    'IL',
    'IND',
    'IR',
    'IRL',
    'IRQ',
    'IS',
    'J',
    'JA',
    'JOR',
    'K',
    'KAI',
    'KAN',
    'KIB',
    'KN',
    'KP',
    'KS',
    'KSA',
    'KWT',
    'KZ',
    'L',
    'LAO',
    'LAR',
    'LB',
    'LS',
    'LT',
    'LV',
    'M',
    'MA',
    'MAC',
    'MAL',
    'MAN',
    'MH',
    'MAT',
    'MAY',
    'MC',
    'MD',
    'MDV',
    'MGL',
    'MEX',
    'MIK',
    'MK',
    'MNE',
    'MOC',
    'MOT',
    'MS',
    'MW',
    'MYA',
    'N',
    'NA',
    'NAM',
    'NAU',
    'NEP',
    'NIC',
    'NIU',
    'NKA',
    'NL',
    'NMA',
    'NZ',
    'O',
    'OM',
    'P',
    'PA',
    'PAL',
    'PE',
    'PIE',
    'PIN',
    'PIT',
    'PK',
    'PL',
    'PRI',
    'PNG',
    'PR',
    'PRK',
    'PY',
    'Q',
    'RA',
    'RB',
    'RC',
    'RCA',
    'RCB',
    'RCH',
    'RDU',
    'RH',
    'REU',
    'RG',
    'RI',
    'RIM',
    'RKS',
    'RL',
    'RM',
    'RMM',
    'RN',
    'RO',
    'ROK',
    'ROU',
    'RP',
    'RSM',
    'RT',
    'RU',
    'RUS',
    'RWA',
    'S',
    'SA',
    'SAL',
    'SCN',
    'SCV',
    'SD',
    'SGP',
    'SK',
    'SLO',
    'SME',
    'SN',
    'SP',
    'SRB',
    'STP',
    'SU',
    'SUD',
    'SWA',
    'SY',
    'SYR',
    'T',
    'TD',
    'TG',
    'TJ',
    'TM',
    'TN',
    'TOK',
    'TR',
    'TT',
    'TUC',
    'TUV',
    'UA',
    'UAE',
    'USA',
    'UZ',
    'V',
    'VAN',
    'VG',
    'VN',
    'WAG',
    'WAL',
    'WAN',
    'WD',
    'WG',
    'WL',
    'WS',
    'WV',
    'YAR',
    'YV',
    'Z',
    'ZA',
    'ZRE',
    'ZW',
    'IDN',
  ];

  private get labelValue(): string {
    return (
      this.label || this.$t('components.country-code-input.label').toString()
    );
  }

  private get countryCode(): string {
    return this.value;
  }

  private set countryCode(newValue: string) {
    this.$emit('input', newValue || '');
  }
}

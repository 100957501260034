









































import {DateRegex} from '@/formatter';
import {Component, Watch, Prop, Vue} from 'vue-property-decorator';

@Component
export default class DatePickerInput extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  public readonly value!: string;
  @Prop({
    type: String,
    required: false,
  })
  public readonly minDate!: string;
  @Prop({
    type: String,
    required: false,
    default: undefined,
  })
  public readonly label?: string;
  @Prop({
    type: Array,
    required: false,
    default: () => [],
  })
  public readonly rules?: {(v: string): string | true}[];
  private textField: string | null = null;
  private picker: string | null = null;
  private showMenu = false;

  private get labelValue(): string {
    return (
      this.label || this.$t('components.date-picker-input.date').toString()
    );
  }

  @Watch('value', {immediate: true})
  private onValueChanged(val: string): void {
    this.picker = val;
  }

  @Watch('outputValue')
  private onOutputValueChanged(): void {
    this.emitValue();
  }

  @Watch('picker', {immediate: true})
  private onPickerChanged(val: string): void {
    let date = null;
    if (val) {
      const [year, month, day] = val.split('-');
      date = `${day.padStart(2, '0')}.${month.padStart(2, '0')}.${year}`;
    }
    this.textField = date;
  }

  @Watch('textField')
  private onTextFieldChanged(val: string): void {
    if (val && DateRegex.test(val)) {
      this.picker = this.setDate(val);
    } else {
      this.picker = '';
    }
  }

  private get outputValue(): string {
    return this.picker || '';
  }

  private setDate(val: string | null): string {
    if (!val) {
      return '';
    }
    const [day, month, year] = val.split('.');
    if (!day || !month || !year) {
      return '';
    }
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  }

  private emitValue(): void {
    this.$emit('input', this.outputValue);
  }

  private openMenu() {
    this.showMenu = true;
  }

  private closeMenu(): void {
    this.showMenu = false;
  }
}






















































































import {Component, Vue} from 'vue-property-decorator';

@Component
export default class CookieDialog extends Vue {
  private isOpen = true;

  private acceptCookies(): void {
    localStorage.setItem('cookieAccept', '1');
    this.isOpen = false;
    this.$root.$emit('closedCookiesDialog');
  }

  private denyCookies(): void {
    const glgLoggedIn = this.$store.getters['glaeubiger/isAuthenticated'];
    localStorage.clear();
    sessionStorage.clear();
    this.$root.$emit('closedCookiesDialog');
    if (glgLoggedIn) {
      this.$store
        .dispatch('glaeubiger/logout')
        .finally(() => this.$router.push('/glg/login'));
    }
  }
}

































































import {Component, Vue} from 'vue-property-decorator';

@Component
export default class Login extends Vue {
  private valid = false;
  private username = '';
  private password = '';
  private showPassword = false;
  private error = '';
  private loginLoading = false;

  private usernameRules = [
    (v: string): string | boolean =>
      !!v || this.$t('views.login.username-input-error').toString(),
  ];

  private passwordRules = [
    (v: string): string | boolean =>
      !!v || this.$t('views.login.password-input-error').toString(),
  ];

  private get isFormValid(): boolean {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const form = this.$refs.form as any;
    return form && form.validate();
  }

  public mounted(): void {
    this.loginLoading = false;
    this.showPassword = false;
    this.error = '';

  }

  private validate(): void {
    if (localStorage.getItem('cookieAccept') !== '1') {
      this.$root.$emit('changeCookies');
      return;
    }
    if (this.isFormValid) {
      this.error = '';
      this.loginLoading = true;
      this.$store
        .dispatch('glaeubiger/login', {
          username: this.username,
          password: this.password,
        })
        .then(() => this.$router.push('/glg'))
        .catch(() => {
          this.error = this.$t('views.login.alert-login-failed').toString();
          this.loginLoading = false;
        });
    }
  }
}

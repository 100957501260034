import {Payload} from '@/api/payload';

export class SuccessResponsePayload extends Payload {
  generalStatistics: {
    [type: string]: [
      number, // number
      number, // HF
      number, // brutto
      number // netto
    ];
  } = {};
  specificStatistics: {
    [type: string]: [
      number, // number
      number, // HF
      string // catefgory
    ];
  } = {};
  brutto: string;
  netto: string;

  constructor(payload: {
    generalStatistics: {
      [type: string]: [
        number, // number
        number, // HF
        number, // brutto
        number // netto
      ];
    };
    specificStatistics: {
      [type: string]: [
        number, // number
        number, // HF
        string // catefgory
      ];
    };
    brutto: string;
    netto: string;
  }) {
    super();
    this.generalStatistics = payload.generalStatistics;
    this.specificStatistics = payload.specificStatistics;
    this.brutto = payload.brutto;
    this.netto = payload.netto;
  }
}

import {Payload} from '@/api/payload';

export class FallbetrachtungHauptforderungResponsePayload extends Payload {
  history: FallHauptforderungEntrySubpayload[];

  constructor(body: {history: FallHauptforderungEntrySubpayload[]}) {
    super();
    this.history = body.history;
  }
}

export class FallHauptforderungEntrySubpayload {
  public katnr: number;
  public bezeichnung: string;
  public rechnr: string;
  public vertnr: string;
  public datvon: string;
  public datbis: string;
  public betrag: string;
  public mahnkost: string;
  public bankrl: string;

  constructor() {
    this.katnr = -1;
    this.bezeichnung = '';
    this.rechnr = '';
    this.vertnr = '';
    this.datvon = '';
    this.datbis = '';
    this.betrag = '';
    this.mahnkost = '';
    this.bankrl = '';
  }
}

import {Payload} from '@/api/payload';
import {UebergabeGutschrift} from '@/store/model';

export class NachmeldungGutschriftenRequestPayload extends Payload {
  inkassoNumber = '';
  gutschriften: UebergabeGutschrift[] = [];

  constructor() {
    super();
  }
}
